// 防抖函数
function debounce(fn, delay, store) {
    let timer = null; // 声明计时器（创建一个标记用来存放定时器的返回值
    return function (...args) {
        clearTimeout(timer);
        timer = setTimeout(function () {
            fn.apply(this, [args, store]);
        }, delay);
    };
}

// 获取窗口变化的值
const getScreenWidth = function (...args) {
    const useMain = args[1];
    const useStoreMain = useMain();
    let w = document.documentElement.clientWidth;
    let h = document.documentElement.clientHeight;
    useStoreMain.setWindowSize([w, h]);
};

// 监听窗口变化
export default function addEventListenerWindowSize(useMain) {
    window.addEventListener("resize", debounce(getScreenWidth, 100, useMain));
};