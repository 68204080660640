import { createApp } from 'vue'

// ant-design-vue style
import 'ant-design-vue/es/message/style/css'

import setupAtnd from "@/module/antDesign/index.js"

import { setUpPinia, useMain } from "@/store/index"

import setUpRouter from "@/router/index"

import addEventListenerWindowSize from "@/utils/getWindowSize"

import App from './App.vue'

// 注册eventBus
import EventBus from '@/utils/eventBus'

// create vue
const app = createApp(App)

// set up ant-desing-vue 
setupAtnd(app)

// set up pinia
setUpPinia(app)

setUpRouter(app, useMain)

// Listener window size
addEventListenerWindowSize(useMain)


const debounce = (fn, delay) => {
    let timer = null;
    return function () {
        let context = this;
        let args = arguments;
        clearTimeout(timer);
        timer = setTimeout(function () {
            fn.apply(context, args);
        }, delay);
    };
};

const _ResizeObserver = window.ResizeObserver;
window.ResizeObserver = class ResizeObserver extends _ResizeObserver {
    constructor(callback) {
        callback = debounce(callback, 16);
        super(callback);
    }
};

// 挂载事件总线
const $bus = new EventBus();
app.config.globalProperties.$bus = $bus;

// Mount vue
app.mount('#app')
