// 节流函数
function throttle(func, delay) {
    let beginTime = 0;
    return function (...args) {
        let timer = +new Date();
        if (timer - beginTime > delay) {
            func.apply(this, args);
            beginTime = timer;
        }
        ;
    };
};


// 防抖函数
function debounce(func, wait) {
    let timer = null;
    return function (...args) {
        let first = !timer;
        if (timer) clearTimeout(timer);
        if (first) func.apply(this, args);
        timer = setTimeout(() => {
            timer = null;
        }, wait)
    }
};

// 验证邮箱
function validateEmail(email) {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
}

// 验证中国大陆手机号码
function validateMobileNumber(mobileNumber) {
    const regex = /^1[3-9]\d{9}$/;
    return regex.test(mobileNumber);
}


function addUrlUserId(url, urlSymbol,  userId) {
    let resultUrl = url;
    if(!resultUrl) {
        return resultUrl;
    }
    if(url.includes("userId")) {
        // console.log("存在userId", url)
        let urlAry = url.split(urlSymbol);
        urlAry[urlAry.length - 1] = "userId=" + userId;
        resultUrl = urlAry.join(urlSymbol);
    } else {
        // console.log("不存在userId", url)
        resultUrl = url + urlSymbol +"userId=" + userId;
    }
    return resultUrl;
}

function randomNumber(num = 3) {
    let randomNumber = "9";
    for(let i = 0; i < num; i++) {
        randomNumber += Math.floor(Math.random() * 11);
    }
    return Number(randomNumber)
}

export {
    throttle,
    debounce,
    validateEmail,
    validateMobileNumber,
    addUrlUserId,
    randomNumber
}