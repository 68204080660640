import { createRouter, createWebHistory, createWebHashHistory, useRouter } from 'vue-router'
import LocalStoreAPI from "@/utils/localStoreUtil";
import { encrypt, decrypt } from "@/utils/crypto";
import { getAction, postAction } from "@/server/request/service";
import { loginUrl } from "@/utils/queryUrl";
import { setLocalUserInfo } from "@/views/login/index.js";

// 进度条
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';
// 初始化 NProgress
NProgress.configure({ showSpinner: false });


const routes = [];

export default function setUpRouter(app, useMain) {
    const useStoreMain = useMain();

    // 访问根路由跳转首页
    routes.push({
        path: '/',
        redirect: '/home/homepage'
    });
    // 访问/home路由跳转首页
    routes.push({
        path: '/home',
        redirect: '/home/homepage'
    });
    // 登录页
    routes.push({
        path: '/login',
        redirect: '/login/type=login'
    });
    // 404
    routes.push({
        path: '/:pathMatch(.*)*',
        name: 'notFound',
        meta: { title: "Not Found" },
        component: () => import('@/components/NotFound.vue')
    });

    let token = LocalStoreAPI.get("ACCESS_TOKEN");
    let userInfo = LocalStoreAPI.getdecrypt("USER_INFO", token);
    console.log("路由页面获取用户信息:", userInfo)
    useStoreMain.setUserInfo(userInfo);
    const menuItem = useStoreMain.getMenuItem;

    // 动态注册路由
    getRouter(menuItem, useStoreMain);

    // 创建路由实例
    const router = createRouter({
        history: createWebHistory(),
        // history: createWebHashHistory(),
        routes, // `routes: routes` 的缩写
    })

    // 全局路由守卫
    router.beforeEach((to, from, next) => {
        routerBeforeEach(to, from, next, router, useStoreMain)
    });

    // 挂载路由
    app.use(router)
};


// 注册路由
function getRouter(menuItem, useStoreMain) {
    console.log("动态注册路由", menuItem)
    menuItem.forEach(item => {
        // 一级路由
        let routerItem = {
            name: item.name,
            path: item.path,
            meta: { title: item.title },
            component: () => import(`@/views/${item.component}.vue`),
            children: []
        };

        // 二级路由
        item.children.forEach(childrenItem => {
            let router = {
                path: childrenItem.path,
                name: childrenItem.name,
                meta: { title: childrenItem.title },
                component: () => import(`@/views/${childrenItem.component}.vue`),
                children: []
            };
            const routerFun = ["homepage", "componentCalculation", "finiteElements"];
            if (routerFun.includes(childrenItem.name)) {
                router.beforeEnter = (to, from, next) => {
                    NProgress.start();
                    useStoreMain.setAppList().finally(() => {
                        NProgress.done();
                        next();
                    })
                }
            }
            routerItem.children.push(router)
        });

        routes.push(routerItem);
    });
};

// 全局路由路由守卫
function routerBeforeEach(to, from, next, router, useStoreMain) {
    console.log("全局路由守卫", to)
    document.title = to.meta.title || '结构计算网';
    let routerName = to.name;
    // 微信登录
    if (routerName == "wecharlogin") {
        wecharloginBeforeEach(to, from, next, router, useStoreMain)
    }
    else if (routerName == "rebarMaterials") {
        if (useStoreMain.userInfo && Object.keys(useStoreMain.userInfo).length > 0) {
            next();
        } else {
            next({ name: "login", params: { type: `type=login` } });
        }
    } else {
        next();
    }
}

// 微信路由导航
function wecharloginBeforeEach(to, from, next, router, useStoreMain) {
    NProgress.start();
    console.log("微信路由导航", to)
    let code = to.query.code;
    let login = { name: "login", params: { type: `type=login` } }
    getAction(loginUrl.wecharLogin, { code }).then(res => {
        console.log(res)
        if (res.success) {
            if (res.result.wechatId) {
                console.log("绑定手机号")
                login.params.type = `type=setup&code=${res.result.wechatId}`
                next(login)
            } else {
                console.log("登录成功")
                setLocalUserInfo(res.result, useStoreMain, router);
                next({ name: "homepage" })
            }
        } else {
            next(login);
        }
    }).catch(() => {
        next(login);
    }).finally(() => {
        NProgress.done();
    })

}
