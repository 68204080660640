import LocalStoreAPI from "@/utils/localStoreUtil";
import { encrypt, decrypt } from "@/utils/crypto";


function getRouterParans(type) {
    let par = null;
    if (type) {
        par = type.split("&")[0]?.split("=")[1];
    }
    return par;
};

// 将userInfo加密和token缓存
function setLocalUserInfo(result, useStoreMain, router) {
    console.log("设置数据")
    let token = result.token;
    let userInfo = result.userInfo;
    
    LocalStoreAPI.set("ACCESS_TOKEN", token);
    LocalStoreAPI.setEncrypt("USER_INFO", userInfo, token);
    // let ruleEncrypt = encrypt(userInfo, token);
    // LocalStoreAPI.set("USER_INFO", ruleEncrypt);
    useStoreMain.setUserAndDynamicRouter(userInfo, router);
}


let validatePhone = async (_rule, value) => {
    console.log(value);
    // 手机号码验证规则
    const phoneRegex = /^1[0-9]{10}$/;
    return new Promise((resolve, reject) => {
        if (value && !phoneRegex.test(value)) {
            reject(new Error("手机号码格式不正确"));
        } else {
            resolve();
        }
    });
};

let validatePassword = async (_rule, value) => {
    return new Promise((resolve, reject) => {
        if (value && (value.length > 20 || value.length < 6)) {
            reject(new Error("请输入6-20位密码"));
        } else {
            resolve();
        }
    });
};

let validateUserName = async (_rule, value) => {
    return new Promise((resolve, reject) => {
        if (value && (value.trim().length > 10 || value.trim().length < 2)) {
            reject(new Error("请输入2-10位用户名"));
        } else {
            resolve();
        }
    });
};



export {
    getRouterParans,
    setLocalUserInfo,
    validatePhone,
    validatePassword,
    validateUserName
}