<template>
  <div v-if="type == 'componentCalculation' || type == 'finiteElements'" class="home-tabs">
    <a-tabs v-model:activeKey="activeKey" centered>
      <a-tab-pane v-for="item in dataSource" :key="item.id" :tab="item.title">
        <a-list :grid="grid" :data-source="item.children">
          <template #renderItem="{ item }">
            <a-list-item @click="handleClickCard(item)">
              <a-card hoverable>
                <template #cover>
                  <div class="card-img">
                    <img
                      alt="example"
                      :src="item.icon"
                      :onerror="loadImageError"
                      style="width: 150px; height: 150px"
                    />
                  </div>
                </template>
                <a-card-meta :title="item.title">
                  <template #description>
                    <div class="double-line">
                      {{ item.description }}
                    </div>
                  </template>
                </a-card-meta>
                <template #actions>
                  <a-tooltip>
                    <template #title>{{ item.description }}</template>
                    描述
                  </a-tooltip>
                </template>
              </a-card>
            </a-list-item>
          </template>
        </a-list>
      </a-tab-pane>
      <template #leftExtra>
        <span class="title">{{ title }}</span>
      </template>
      <template #rightExtra>
        <span :style="{ marginLeft: '100px' }" @click="allClick">
          <a :href="jumpUrl">全部 <CaretRightOutlined /></a>
        </span>
      </template>
    </a-tabs>
  </div>

  <div v-else-if="type == 'rebarMaterials'" class="home-tabs">
    <div class="not-tabs-title">
      <div class="title title-margin">{{ title }}</div>
      <div>
        <a :href="jumpUrl">全部 <CaretRightOutlined /></a>
      </div>
    </div>
    <a-list :grid="grid" :data-source="dataSource">
      <template #renderItem="{ item }">
        <a-list-item @click="handleClickCard(item)">
          <a-card hoverable>
            <template #cover>
              <img alt="example" :src="item.pic" />
            </template>
            <a-card-meta :title="item.title">
              <template #description>
                <div class="double-line">
                  {{ item.description }}
                </div>
              </template>
            </a-card-meta>
            <!-- <template #actions>
            <a-tooltip>
              <template #title>{{ item.description }}</template>
              描述
            </a-tooltip>
          </template> -->
          </a-card>
        </a-list-item>
      </template>
    </a-list>
  </div>

  <div v-else class="home-tabs">
    <div class="not-tabs-title">
      <div class="title title-margin">{{ title }}</div>
      <div>
        <a :href="jumpUrl">全部 <CaretRightOutlined /></a>
      </div>
    </div>
    <a-list :grid="grid" :data-source="dataSource">
      <template #renderItem="{ item }">
        <a-list-item @click="handleClickCard(item)">
          <a-card hoverable>
            <template #cover>
              <img alt="example" :src="item.pic" />
            </template>
            <a-card-meta :title="item.title">
              <template #description>
                <div class="double-line">
                  {{ item.description }}
                </div>
              </template>
            </a-card-meta>
          </a-card>
        </a-list-item>
      </template>
    </a-list>
  </div>


</template>

<script>
import { ref, computed } from "vue";
import { useRouter, useRoute } from "vue-router";
export default {
  name: "HomeTabs",
  props: {
    type: String,
    grid: {
      type: Object,
      require: true,
    },
    title: String,
    dataSource: {
      type: Array,
      default: [],
    },
    pagination: {
      type: Object,
      require: true,
    },
  },
  setup(props) {
    console.log(props.type, props.dataSource);
    const router = useRouter();
    const activeKey = ref(props.dataSource[0]?.id);
    const jumpUrl = computed(() => {
      return "/home/" + props.type;
    });
    const loadImageError = function () {
      let imageUrl = require("@/assets/no_image.png");
      if (imageUrl) {
        this.src = imageUrl;
      } else {
        this.οnerrοr = null;
      }
    };
    const handleClickCard = (item) => {
      console.log(item.url);
      // router.push({ name: props.type, params: { page: item.id } });
      router.push({
        name: props.type,
        query: {
          page: item.id,
        },
      });
    };

    const allClick = () => {};
    return {
      activeKey,
      jumpUrl,
      loadImageError,
      handleClickCard,
      allClick,
    };
  },
};
</script>

<style lang="less" scoped>
.home-tabs {
  .px2vw(margin-top, 40);
  .px2vw(padding, 20);
  background-color: #fff;
  box-shadow: rgba(23, 25, 29, 0.2) 0px 16px 16px -16px,
    rgba(23, 25, 29, 0.03) 0px 14px 20px 0px,
    rgba(23, 25, 29, 0.04) 0px 0px 0px 1px;
  border-radius: 2px;
}
.double-line {
  word-break: break-all;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.title {
  .px2vw(margin-right, 100);
  .px2vw(font-size, 26);
  font-weight: 900;
}
.card-img {
  text-align: center;
  padding-top: 8px;
}
.not-tabs-title {
  display: flex;
  justify-content: space-between;
}
.title-margin {
  .px2vh(padding-bottom, 20);
}
</style>