<template>
  <div class="commont-box">
    <div class="footer-content commont-content">
      <a-row>
        <a-col span="8">
          <p class="footer-text">核心产品</p>
          <p class="footer-text">
            <a href="/home/componentCalculation">构件计算</a>
            <a-divider type="vertical" style="background-color: #fff" />
            <a href="/home/finiteElements">有限元</a>
            <a-divider type="vertical" style="background-color: #fff" />
            <a href="/home/rebarMaterials">钢筋翻样</a>
          </p>
        </a-col>
        <a-col span="8">
          <p class="footer-text" @click="aboutMe">
            <a>关于我们</a>
          </p>
          <p class="footer-text">
            <!-- <a href="http://www.toolkip.com/" target="_blank">企业官网</a> -->
          </p>
        </a-col>
        <a-col span="8">
          <p class="footer-text">联系我们</p>
          <p class="footer-text">
            <a><MailOutlined />&nbsp;&nbsp;404319280@qq.com</a>
          </p>
        </a-col>
        <a-col span="24">
          <p class="footer-conpyright">
            <!-- Copyright © 2023-上海图启建筑科技股份有限公司版权所有 -->
            <a
              href="https://beian.miit.gov.cn/"
              target="_blank"
              style="color: #000"
              >结构计算网&nbsp;沪ICP备05001618号-1</a
            >
          </p>
        </a-col>
      </a-row>
    </div>
  </div>
  <AboutUs ref="aboutUs" />
</template>

<script>
import { ref } from "vue";
import AboutUs from "@/views/home/AboutUs.vue";
export default {
  name: "HomeFooter",
  components: {
    AboutUs,
  },
  setup() {
    const aboutUs = ref(null);

    const aboutMe = () => {
      aboutUs.value.showModal();
    };

    return {
      aboutUs,
      aboutMe,
    };
  },
};
</script>

<style lang="less" scoped>
.commont-box {
  width: 100%;
  background-color: #1890ff;
  margin-top: 20px;
}
.commont-content {
  max-width: 1200px;
  margin: 0 auto;
}
.footer-content {
  padding: 20px 0;
  .footer-text {
    color: #fff;

    a {
      color: #fff;
    }

    a:hover {
      color: #000;
    }
  }
  .footer-conpyright {
    .px2vh(margin-top, 16);
    text-align: center;
    margin-bottom: 0;
  }
}
</style>