<template>
  <router-view> </router-view>
  <!-- <Basic /> -->
</template>

<script>
import { onMounted, getCurrentInstance } from "vue";
import { useRouter, useRoute } from "vue-router";
import Basic from "@/views/layouts/Basic.vue";
import { throttle } from "@/utils/utils";
import { useMain } from "@/store/index";
export default {
  name: "App",
  components: {
    Basic,
  },
  setup() {
    const { $message, $bus } =
      getCurrentInstance().appContext.config.globalProperties;
    const useStoreMain = useMain();
    const router = useRouter();
    const route = useRoute();

    // const devOrProd = process.env.NODE_ENV == "development" ? "https://4031y7s390.yicp.fun" : "";
    // console.log(devOrProd)

    onMounted(() => {
      window.addEventListener("message", throttle(listenerMessage, 200), false);
    });

    function listenerMessage(event) {
      console.log("监听子组件发消息", event, event.data);
      // if (event.origin == "https://4031y7s390.yicp.fun") {
      let message = event.data;
      if (message && typeof message === "string") {
        let pathName = route.name;
        let userInfo = useStoreMain.userInfo;
        console.log(pathName, userInfo);
        // 跳转登录
        if (!userInfo || Object.keys(userInfo).length == 0) {
          // 判断当前页面是否时登录页面
          if (pathName != "login") {
            $message.warning("请先登录");
            router.push({ name: "login", params: { type: "type=login" } });
            return;
          }
        }

        if (message == "nopay") {
          // 跳转付款页面
          if (pathName != "pay") {
            $message.warning("请先购买会员,享受后续服务");
            router.push({ name: "pay" });
          }
        } else if(message == "question") {
          // 反馈
          console.log($bus)
          $bus.emit("openFeedback")
        } else if(message == "open") {
          $bus.emit("openBook")
        }
      }
      // }
    }
  },
};
</script>

<style lang="less" scoped>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>>


