

const getListUrl = {
    allAppList: "/api/open/getApp"
}

const loginUrl = {
    login: "/api/login",
    setup: "/api/setup",
    sendSms: "/api/smsSend",
    wecharLogin: "/api/wecharLogin"
}

const feedBackUrl = {
    list: "sys/feedBack/list",
    delete: "sys/feedBack/delete",
    sendEmail: "sys/feedBack/sendEmail"
}

const userSetting = {
    editPhone: "/sys/userinfo/editPhone",
    editEmail: "/sys/userinfo/editEmail",
    sendEmail: "/sys/userinfo/sendEmail",
    editPassword: "/sys/userinfo/editPassword",
    editAutoSave: "/sys/userinfo/editAutoSave",
    softwareCode: "/sys/userinfo/softwareCode",
    tkpmCode: "/sys/userinfo/tkpmCode"
}

const userPay = {
    alipay: "/sys/userPay/alipay",
    checkOrders: "/sys/userPay/checkOrders",
    syncMembers: "/sys/userPay/syncMembers",
    payPurchase: "/sys/userPay/payPurchase"
}

const revitUrl = {
    videoList: "/api/open/videoList"
}

const openApi = {
    sendCompanyContact: "/api/open/sendCompanyContact"
}

export {
    getListUrl,
    loginUrl,
    feedBackUrl,
    userSetting,
    userPay,
    revitUrl,
    openApi
}