<template>
  <div v-show="visible" class="f-modal">
    <div class="f-modal-content">
      <video ref="video" controls>
        <source
          src="http://121.4.30.16:9000/pdf2rebar/video/diagrams.mp4"
          type="video/mp4"
        />
      </video>
      <div class="f-modal-close-btn" @click="toggleModal(false)">
        <CloseOutlined />
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
export default {
  name: "FModal",
  setup() {
    let visible = ref(false);
    const video = ref(null);
    let toggleModal = (value) => {
      console.log("触发", video.value)
      visible.value = value;
      if(!value) {
        video.value.pause();
      }
    };
    return {
      visible,
      video,
      toggleModal,
    };
  },
};
</script>

<style lang="less" scoped>
.f-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(10px);
  display: flex;
  justify-content: center;
  align-items: center;
}
.f-modal-content {
  .px2vw(width, 1200);
  .px2vh(height, 700);
  position: relative;
  video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover; /* 调整视频填充方式，可以根据实际情况选择 */
  }
}
.f-modal-close-btn {
    position: absolute;
    top: -10%;
    right: -6%;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    line-height: 40px;
    text-align: center;
    font-size: 26px;
    color: #fff;
    border: 1px solid #fff;
}
</style>