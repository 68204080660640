import axios from "axios";
import { notification } from 'ant-design-vue';
import LocalStoreAPI from "@/utils/localStoreUtil";
import { useMain } from "@/store/index";
let apiBaseUrl = process.env.VUE_APP_API_BASE_URL || "/famous-build";

// 创建 axios 实例
const service = axios.create({
  baseURL: apiBaseUrl,
  timeout: 9000,  // 请求超时时间
});

/* 错误定义 */
const err = (error) => {
  console.log(error.response)
  // const { $message } = getCurrentInstance().appContext.config.globalProperties;
  if (error.response) {
    let data = error.response.data;
    // const token = Vue.ls.get(ACCESS_TOKEN)
    switch (error.response.status) {
      case 0:
        notification["error"]({
          message: '系统提示',
          description: error.message,
        });
        break;
      case 403:
        notification["error"]({
          message: '系统提示',
          description: '拒绝访问!',
        });
        break;
      case 500:
        notification["error"]({
          message: '系统提示',
          description: '服务器错误!',
        });
        // Vue.prototype.$notification.error({
        //   message: "系统提示",
        //   description: "服务器错误",
        //   duration: 4,
        // });
        break;
      case 404:
        notification["error"]({
          message: '系统提示',
          description: '很抱歉，资源未找到!',
        });
        // Vue.prototype.$Jnotification.error({ message: '系统提示', description:'很抱歉，资源未找到!',duration: 4})
        break;
      case 504:
        notification["error"]({
          message: '系统提示',
          description: '网络超时',
        });
        //   Vue.prototype.$Jnotification.error({ message: '系统提示', description: '网络超时'})
        break;
      case 401:
        notification["error"]({
          message: '系统提示',
          description: '很抱歉，登录已过期，请重新登录',
        });

        //   Vue.prototype.$Jnotification.error({ message: '系统提示', description:'很抱歉，登录已过期，请重新登录',duration: 4})
        //   if (token) {
        //     store.dispatch('Logout').then(() => {
        //       setTimeout(() => {
        //         window.location.reload()
        //       }, 1500)
        //     })
        //   }
        break;
      default:
        notification["error"]({
          message: '系统提示',
          description: data.message,
        });
        break;
    }
  } else if (error.message) {
    if (error.message.includes("timeout")) {
      notification["error"]({
        message: '系统提示',
        description: '网络超时',
      });
    } else {
      notification["error"]({
        message: '系统提示',
        description: error.message,
      });
        $message.success(error.message)
    }
  }
  return Promise.reject(error);
};

/* 请求拦截 */
service.interceptors.request.use(
  (config) => {
    // 让每个请求携带自定义 token 请根据实际情况自行修改
    // const token = Vue.ls.get(ACCESS_TOKEN)
    let token = LocalStoreAPI.get("ACCESS_TOKEN");
    if (token) {
        config.headers['X-Access-Token'] = token;
    } else {
      
      LocalStoreAPI.remove("USER_INFO")
      const useStoreMain = useMain();
      useStoreMain.setUserInfo(null);
    }
    // const $route = router.currentRoute
    // if ($route && $route.name && $route.name.startsWith('low-app') && $route.params.appId) {
    //     config.headers['X-Low-App-ID'] = $route.params.appId
    // }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

/* 响应拦截 */
service.interceptors.response.use((response) => {
  return response.data;
}, err);

export { service as axios };
