<template>
  <a-row>
    <a-col
      :xs="{ span: 22, offset: 1 }"
      :lg="{ span: 12 }"
      class="home-content-description"
    >
      <p>简化您的结构工程</p>
      <p>致力于为工程师提供建筑、结构、设备全专业在线计算服务</p>
      <p>
        <CheckOutlined class="description-icon" />即时结果=更快的工程设计<br />
        <CheckOutlined class="description-icon" />设计广泛的计算范围<br />
        <CheckOutlined class="description-icon" />更快的计算<br />
        <CheckOutlined class="description-icon" />随时随地高效工作<br />
      </p>
    </a-col>

    <a-col
      :xs="{ span: 22, offset: 1 }"
      :lg="{ span: 10 }"
      class="home-content-demo"
    >
      <div class="home-content-vidao">
        <!-- <img src="@/assets/demo.png" alt="" style="width:100%;height:100%">-->
        <div class="home-content-vidao-watch">
          <a-button type="primary" ghost @click="showDemoModal"
            >观看演示</a-button
          >
        </div>
      </div>
    </a-col>

    <a-col
      :xs="{ span: 22, offset: 1 }"
      :lg="{ span: 22 }"
      class="home-carousel-map"
    >
      <div class="home-carousel-map-text">
        <hr />
        <p>结构有限元</p>
        <hr />
      </div>
      <a-carousel arrows autoplay class="test">
        <!-- <template #prevArrow>
                <div class="custom-slick-arrow" style="left: -30px;z-index: 1">
                  <left-circle-outlined style="color:rgba(31, 45, 61, 0.5);" />
                </div>
              </template>
              <template #nextArrow>
                <div class="custom-slick-arrow" style="right: -30px;">
                  <right-circle-outlined style="color:rgba(31, 45, 61, 0.5);" />
                </div>
              </template> -->
        <div>
          <img src="@/assets/home_img.jpg" alt="" style="width: 100%" />
        </div>
        <div>
          <img src="@/assets/home_img.jpg" alt="" style="width: 100%" />
        </div>
        <!-- <div>
                <img src="@/assets/page1-1.jpg" alt="" style="width: 100%" />
              </div>
              <div>
                <img src="@/assets/page2-1.jpg" alt="" style="width: 100%" />
              </div> -->
      </a-carousel>
    </a-col>
  </a-row>
  <FModal ref="fmodal" />
</template>

<script>
import { ref } from "vue";
import FModal from "@/components/FModal.vue";
export default {
  name: "HomePage1",
  components: {
    FModal,
  },
  setup() {
    const fmodal = ref();
    const showDemoModal = () => {
      fmodal.value.toggleModal(true);
    };

    return {
      fmodal,
      showDemoModal,
    };
  },
};
</script>

<style lang="less" scoped>
.home-content {
  max-width: 1200px;
  height: 100%;
  margin: 0 auto;

  .home-content-description {
    .px2vw(padding-top, 120);

    p {
      letter-spacing: 2px;
      color: #fff;
    }

    p:nth-child(1) {
      .px2font(36);
      .px2vh(margin-bottom, 26);
    }

    p:nth-child(2) {
      .px2font(22);
      .px2vh(margin-bottom, 10);
    }

    p:nth-child(3) {
      .px2font(18);
      .px2vh(line-height, 40);
    }

    .description-icon {
      margin-left: 20px;
      margin-right: 8px;
    }
  }

  .home-content-demo {
    .px2vw(padding-top, 120);

    .home-content-vidao {
      .px2vw(width, 540);
      .px2vh(height, 320);
      background-image: url("@/assets/demo.jpg");
      background-size: cover;
      position: relative;
      min-width: 300px;
      border: 4px solid #1b3a95;
      // border: 4px solid #2c3e50;
      border-radius: 4px;
      // border-style: double;

      .home-content-vidao-watch {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.3);
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  .home-carousel-map {
    .px2vw(margin-top, 190);
    // .test {
    //   .px2vh(margin-top, 60);
    // }
    .home-carousel-map-text {
      display: flex;
      justify-content: center;
      align-items: center;
      .px2vh(margin-bottom, 20);

      hr {
        width: 260px;
        height: 2px;
      }

      p {
        .px2vh(font-size, 26);
        margin: 0;
      }
    }

    .img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.ant-carousel {
  :deep(.slick-slide) {
    text-align: center;
    .px2vw(height, 220);
    // .px2vh(line-height, 200);
    overflow: hidden;
    background-size: cover;
    // background-color: pink;
  }

  :deep(.slick-arrow.custom-slick-arrow) {
    width: 25px;
    height: 25px;
    font-size: 25px;
    color: #fff;
    opacity: 0.3;
    z-index: 1;
    top: 50%;
  }

  :deep(.custom-slick-arrow:before) {
    display: none;
  }

  :deep(.custom-slick-arrow:hover) {
    opacity: 0.5;
  }

  :deep(.slick-slide h3) {
    color: #fff;
  }
  .custom-slick-arrow {
    bottom: 0;
  }

  :deep(.slick-dots li button) {
    background-color: rgba(0, 0, 0, 1);
  }

  :deep(.slick-dots-bottom) {
    bottom: -30px;
  }

  :deep(.slick-dots li) {
    width: 60px;
  }
}
</style>