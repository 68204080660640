import { axios } from "../index"
import qs from "qs";


/* GET */
export function getAction(url, parameter) {
    // let sign = signMd5Utils.getSign(url, parameter);
    //将签名和时间戳，添加在请求接口 Header
    // let signHeader = { "X-Sign": sign, "X-TIMESTAMP": signMd5Utils.getDateTimeToString() };
  
    return axios({
      url: url,
      method: 'get',
      params: parameter,
      // headers: signHeader
    })
  }
  
  /* POST */
  export function postAction(url, parameter) {
    // let sign = signMd5Utils.getSign(url, parameter);
    //将签名和时间戳，添加在请求接口 Header
    // let signHeader = {"X-Sign": sign,"X-TIMESTAMP": signMd5Utils.getDateTimeToString()};
  
    return axios({
      url: url,
      method: 'post',
      data: parameter,
      //   headers: signHeader
    })
  }


  /* Delete */
  export function deleteAction(url, parameter) {
    return axios({
      url: url,
      method: 'delete',
      data: parameter,
      //   headers: signHeader
    })
  }
  
  /* POST 超时 */
  export function postActionTime(url, parameter) {
    return axios({
      url: url,
      method: 'post',
      data: parameter,
      timeout: 600000
    })
  }
  
  /* PUT */
  export function putAction(url, parameter) {
    return axios({
      url: url,
      method: 'put',
      data: parameter
    })
  }