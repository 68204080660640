<template>
<a-config-provider :locale="zhCN">
  <!-- <div class="hear-info">2023-08-08起改版测试，老用户重新注册后在用户中心同步原身份和数据，如有疑问请联系我们QQ:404319280</div> -->
  <a-layout>
    <a-layout-header :style="{ background: '#fff', padding: 0, 'border-bottom': '1px solid #f0f0f0' }">
      <Header />
    </a-layout-header>
    <a-layout-content :style="{height: 'calc(100vh - 64px)', overflowX: 'auto'}">  
      <router-view></router-view>
    </a-layout-content>
  </a-layout>
</a-config-provider>
</template>

<script>
import Header from "@/views/layouts/Header.vue";
import Home from "@/views/home/Home.vue";
import zhCN from 'ant-design-vue/es/locale/zh_CN';
export default {
  name: "Basic",
  components: {
    Header,
    Home
  },
  setup() {
    return {
      zhCN
    }
  },
};
</script>

<style>
.box {
  overflow-x: auto;
}
.hear-info {
  width: 100%;
  background-color: #e0415e;
  text-align: center;
}
</style>