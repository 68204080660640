import CryptoJS from 'crypto-js';
const overdueTime = 24 * 60 * 60;
const LocalStoreAPI = {
    // 设置值
    set(key, value, expirationTimeInSeconds = overdueTime) {
        let expirationTime = expirationTimeInSeconds * 1000 + Date.now();
        const item = {
            value: value,
            expirationTime
        };
        localStorage.setItem(key, JSON.stringify(item));
    },

    // 获取值
    get(key) {
        const item = localStorage.getItem(key);
        if (item) {
            const parsedItem = JSON.parse(item);
            if (parsedItem.expirationTime >= Date.now()) {
                return parsedItem.value;
            } else {
                localStorage.removeItem(key);
            }
        }
        return null;
    },

    // 从新设置值
    againSetUserInfo(key, newValue, salt){
        const item = localStorage.getItem(key);
        if(item) {
            let parsedItem = JSON.parse(item);
            let userInfo = this.decrypt(parsedItem.value, salt);
            for(let key in newValue) {
                userInfo[key] = newValue[key];
            }
            let value = this.encrypt(userInfo, salt);
            let expirationTime = parsedItem.expirationTime >= Date.now() ? parsedItem.expirationTime : overdueTime * 1000 + Date.now();
            const saveItem = {
                value,
                expirationTime
            };
            localStorage.setItem(key, JSON.stringify(saveItem));
            return userInfo;
        }
    },

    // 设置加密数据
    setEncrypt(key, data, salt, expirationTimeInSeconds = overdueTime) {
        const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(data), salt).toString();

        let expirationTime = expirationTimeInSeconds * 1000 + Date.now();
        const item = {
            value: encryptedData,
            expirationTime
        };
        localStorage.setItem(key, JSON.stringify(item));
    },

    // 获取加密数据
    getdecrypt(key, salt) {
        const item = localStorage.getItem(key);
        if (item) {
            console.log(item)
            const parsedItem = JSON.parse(item);
            console.log(parsedItem)
            if (parsedItem.expirationTime >= Date.now()) {
                let decryptedData = CryptoJS.AES.decrypt(parsedItem.value, salt).toString(CryptoJS.enc.Utf8);
                decryptedData = JSON.parse(decryptedData)
                return decryptedData;
            } else {
                localStorage.removeItem(key);
            }
        }
        return null;
    },

    // 移除指定键
    remove(key) {
        localStorage.removeItem(key);
    },

    // 清除所有
    clear() {
        localStorage.clear();
    },

    // 加密数据
    encrypt(data, key) {
        data = JSON.stringify(data);
        const encryptedData = CryptoJS.AES.encrypt(data, key).toString();
        return encryptedData;
    },

    decrypt(data, key) {
        let decryptedData = null;
        if (data && key) {
            decryptedData = CryptoJS.AES.decrypt(data, key).toString(CryptoJS.enc.Utf8);
            decryptedData = JSON.parse(decryptedData)
            return decryptedData
        }
        return decryptedData;
    }
};

export default LocalStoreAPI;