<template>
  <a-carousel arrows autoplay>
    <template #prevArrow>
      <div class="custom-slick-arrow" style="left: 10px; z-index: 1">
        <left-circle-outlined />
      </div>
    </template>
    <template #nextArrow>
      <div class="custom-slick-arrow" style="right: 10px">
        <right-circle-outlined />
      </div>
    </template>
    <div class="banner-img-box" v-for="item in bannerImg" :key="item.key">
      <img
        class="banner-img"
        :src="item.src"
        alt=""
        @click="bannerClick(item.url)"
      />
    </div>
  </a-carousel>
</template>
<script>
import { defineComponent, reactive } from "vue";
import { useRouter, useRoute } from "vue-router";
export default defineComponent({
  name: "HomeBanner",
  setup() {
    const router = useRouter();
    const route = useRoute();
    const bannerImg = reactive([
      // {
      //   key: -1,
      //   src: require("@/assets/home_banner1.png"),
      //   url: "",
      // },
      // {
      //   key: 0,
      //   src: require("@/assets/home_banner2.png"),
      //   url: "",
      // },
      {
        key: 1,
        src: require("@/assets/home_banner_one.png"),
        url: "/home/componentCalculation?page=97",
      },
      {
        key: 2,
        src: require("@/assets/home_banner_two.png"),
        url: "/home/finiteElements?page=12",
      },
      {
        key: 3,
        src: require("@/assets/home_banner_three.png"),
        url: "/home/finiteElements?page=150",
      },
      {
        key: 4,
        src: require("@/assets/home_banner_four.png"),
        url: "/home/finiteElements?page=24",
      },
      {
        key: 5,
        src: require("@/assets/home_banner_five.png"),
        url: "/home/rebarMaterials",
      },
    ]);

    const bannerClick = (url) => {
      if (url) {
        console.log(url)
        router.push(url);
        // router.push({
        //   name: url,
        //   query: {
        //     ...route.query,
        //   },
        // });
      }
    };
    return {
      bannerImg,
      bannerClick,
    };
  },
});
</script>
<style lang="less" scoped>
/* For demo */
.ant-carousel {
  :deep(.slick-slide) {
    text-align: center;
    // height: 160px;
    height: calc(100vh - 64px);
    line-height: 160px;
    // background: #364d79;
    overflow: hidden;
  }
  :deep(.slick-arrow.custom-slick-arrow) {
    width: 60px;
    height: 60px;
    font-size: 60px;
    color: rgba(31, 45, 61, 0.5);
    // background-color: rgba(31, 45, 61, 0.11);
    opacity: 0.3;
    z-index: 1;
  }
  :deep(.custom-slick-arrow:before) {
    display: none;
  }
  :deep(.custom-slick-arrow:hover) {
    opacity: 0.5;
  }
  :deep(.slick-dots li) {
    width: 60px;
  }
  :deep(.slick-dots li button) {
    background-color: rgba(0, 0, 0, 1);
  }
}
.banner-img-box {
  width: 100%;
  height: 100vh;
  .banner-img {
    width: 100%;
    height: 94%;
    cursor: pointer;
  }
}
</style>