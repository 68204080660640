import {createPinia} from 'pinia'
import useMain from "./module/main"


// 创建pinia
const pinia = createPinia()

const setUpPinia = (app) => {
    app.use(pinia)
}

export {
    setUpPinia,
    useMain
}

