import 'ant-design-vue/es/message/style/css'
import {
    Button,
    Card,
    Spin,
    Col,
    Row,
    Tree,
    Select,
    Radio,
    Upload,
    Input,
    Modal,
    Dropdown,
    Message,
    Menu,
    Layout,
    Form,
    Space,
    Switch,
    Popover,
    Checkbox,
    Breadcrumb,
    Avatar,
    Table,
    Divider,
    ConfigProvider,
    Popconfirm,
    Tabs,
    Drawer,
    Cascader,
    List,
    Carousel,
    Tooltip,
    BackTop,
    Image,
    Tag,
    InputNumber,
    Pagination,
    Empty
} from 'ant-design-vue';

const components = [
    Modal,
    Button,
    Card,
    Spin,
    Col,
    Row,
    Tree,
    Select,
    Radio,
    Upload,
    Input,
    Dropdown,
    Menu,
    Layout,
    Form,
    Space,
    Switch,
    Popover,
    Checkbox,
    Breadcrumb,
    Avatar,
    Table,
    Divider,
    ConfigProvider,
    Popconfirm,
    Tabs,
    Drawer,
    Cascader,
    List,
    Carousel,
    Tooltip,
    BackTop,
    Image,
    Tag,
    InputNumber,
    Pagination,
    Empty
];

// import * as Icons from "@ant-design/icons-vue";  // 加载所有图标
import {
    StepBackwardOutlined,
    HomeOutlined,
    ProjectOutlined,
    FundProjectionScreenOutlined,
    InsertRowAboveOutlined,
    CheckOutlined,
    LeftCircleOutlined,
    RightCircleOutlined,
    QqOutlined,
    MailOutlined,
    CaretRightOutlined,
    CloseOutlined,
    MenuUnfoldOutlined,
    MenuFoldOutlined,
    FormOutlined,
    PictureOutlined,
    InfoCircleFilled,
    ReloadOutlined,
    SearchOutlined,
    SyncOutlined,
    DeleteOutlined,
    DownOutlined,
    UserOutlined,
    LogoutOutlined,
    AlipayOutlined,
    AlipayCircleFilled,
    SettingOutlined,
    DotChartOutlined,
    CodeSandboxOutlined,
    SlackOutlined,
    SkypeOutlined,
    LineChartOutlined,
    RiseOutlined,
    RadarChartOutlined,
    PieChartOutlined
} from "@ant-design/icons-vue";  // 按需加载图标

const Icons = {
    StepBackwardOutlined,
    HomeOutlined,
    ProjectOutlined,
    FundProjectionScreenOutlined,
    InsertRowAboveOutlined,
    CheckOutlined,
    LeftCircleOutlined,
    RightCircleOutlined,
    QqOutlined,
    MailOutlined,
    CaretRightOutlined,
    CloseOutlined,
    MenuUnfoldOutlined,
    MenuFoldOutlined,
    FormOutlined,
    PictureOutlined,
    InfoCircleFilled,
    ReloadOutlined,
    SearchOutlined,
    SyncOutlined,
    DeleteOutlined,
    DownOutlined,
    UserOutlined,
    LogoutOutlined,
    AlipayOutlined,
    AlipayCircleFilled,
    SettingOutlined,
    DotChartOutlined,
    CodeSandboxOutlined,
    SlackOutlined,
    SkypeOutlined,
    LineChartOutlined,
    RiseOutlined,
    RadarChartOutlined,
    PieChartOutlined
}




export default function setupAtnd(app) {
    // 注册图标
    Object.keys(Icons).forEach(key => {
        app.component(key, Icons[key])
    });

    // 注册组件
    components.forEach((component) => {
        app.use(component);
    });

    // 全局挂载图标
    app.config.globalProperties.$icons = Icons;

    app.config.globalProperties.$message = Message;
}