const rebarList = [
    {
        id: "1",
        appName: "钢筋翻样",
        url: "",
        pic: require("@/assets/materials.jpg"),
        state: 1,
        description: "轻松录入料单，自动计算重量和长度，支持导出excel表格，提供工作效率.",
    },
    {
        id: "2",
        appName: "智能化识别钢筋",
        url: "",
        pic: require("@/assets/identify.jpg"),
        state: 1,
        description: "自动设别pdf图纸中的钢筋，提取图纸中的文字信息和图像信息,更高效,快捷的方式",
    },
    {
        id: "3",
        appName: "简图管理",
        url: "",
        pic: require("@/assets/diagrams.jpg"),
        state: 1,
        description: "已有几百种常用钢筋图形库，支持用户在线绘制钢筋图形，更好的满足用户需求",
    },
    {
        id: "4",
        appName: "参数设置",
        url: "",
        pic: require("@/assets/setting.jpg"),
        state: 1,
        description: "根据不同项目设置不同的参数，使项目更高效更快速",
    },
];

const componentAppList = [
    {
        "id": "1",
        "parentId": "",
        "appName": "基础及地下",
        "url": "",
        "icon": "",
        "pic": "",
        "state": "1",
        "description": "",
        "rule": "",
        "keywords": "",
        "create_time": "",
        "update_time": ""
    },
    {
        "id": "10",
        "parentId": "1",
        "appName": "围墙基础",
        "url": "172.asp",
        "icon": "围墙基础",
        "pic": "gif\\wqjc.gif",
        "state": "1",
        "description": "适用于计算小区围墙基础配筋",
        "rule": "GB 50007-2011",
        "keywords": "小区围墙计算,围墙基础,围墙大门,条形基础,建筑桩基技术规范,JGJ 94-2008,5.2,8.2",
        "create_time": "16/10/2015 00:00:00",
        "update_time": ""
    },
    {
        "id": "100",
        "parentId": "96",
        "appName": "桥梁截面特性计算",
        "url": "191.asp",
        "icon": "桥梁截面特性计算",
        "pic": "选",
        "state": "1",
        "description": "计算常用桥梁截面特性参数",
        "rule": "JGJ D60-2004",
        "keywords": "",
        "create_time": "16/8/2016 00:00:00",
        "update_time": ""
    },
    {
        "id": "101",
        "parentId": "96",
        "appName": "混凝土板模板计算",
        "url": "87.asp",
        "icon": "NO",
        "pic": "选",
        "state": "1",
        "description": "本程序根据《施工计算手册》编制，用于计算混凝土板施工模板和横肋",
        "rule": "JGJ 162-2008",
        "keywords": "",
        "create_time": "6/3/2023 00:00:00",
        "update_time": ""
    },
    {
        "id": "102",
        "parentId": "96",
        "appName": "混凝土梁模板计算",
        "url": "88.asp",
        "icon": "NO",
        "pic": "选",
        "state": "1",
        "description": "本程序根据《施工计算手册》编制，用于计算混凝土梁施工模板和底肋、侧肋、顶撑",
        "rule": "JGJ 162-2008",
        "keywords": "",
        "create_time": "12/3/2023 00:00:00",
        "update_time": ""
    },
    {
        "id": "103",
        "parentId": "96",
        "appName": "混凝土柱模板计算",
        "url": "89.asp",
        "icon": "NO",
        "pic": "选",
        "state": "1",
        "description": "本程序根据《施工计算手册》编制，用于计算混凝土柱施工模板和、肋、对拉螺栓",
        "rule": "JGJ 162-2008",
        "keywords": "",
        "create_time": "16/3/2023 00:00:00",
        "update_time": ""
    },
    {
        "id": "104",
        "parentId": "",
        "appName": "荷载及内力",
        "url": "",
        "icon": "",
        "pic": "",
        "state": "1",
        "description": "",
        "rule": "",
        "keywords": "",
        "create_time": "",
        "update_time": ""
    },
    {
        "id": "105",
        "parentId": "104",
        "appName": "杆件内力求解器",
        "url": "113.asp",
        "icon": "杆件内力求解器",
        "pic": "",
        "state": "1",
        "description": "采用矩阵位移法进行平面刚架内力求解",
        "rule": "结构力学",
        "keywords": "PK,矩阵位移法,平面刚架内力求解器,结构力学",
        "create_time": "1/8/2015 00:00:00",
        "update_time": ""
    },
    {
        "id": "106",
        "parentId": "104",
        "appName": "楼面等效均布荷载",
        "url": "104.asp",
        "icon": "楼面等效均布荷载",
        "pic": "gif\\dxhz1.gif",
        "state": "1",
        "description": "根据《荷规》附录C，分别对单向板上局部荷载的等效均布活荷载（包括有两个相邻局部荷载及悬臂板凳情况）进行计算。",
        "rule": "GB 50009-2012",
        "keywords": "楼面等效活荷载，单向板局部荷载的等效均布活荷载,两个相邻局部荷载,悬臂板凳,建筑结构荷载规范,GB 50009-2012,附录C",
        "create_time": "1/8/2015 00:00:00",
        "update_time": ""
    },
    {
        "id": "107",
        "parentId": "104",
        "appName": "雪荷载标准值",
        "url": "123.asp",
        "icon": "雪荷载",
        "pic": "选",
        "state": "1",
        "description": "本程序考虑计算所在城市50年一遇的基本雪压结合屋面形式计算屋面水平投影面上的雪荷载标准值。",
        "rule": "GB 50009-2012",
        "keywords": "雪荷载,屋面雪荷载",
        "create_time": "1/8/2015 00:00:00",
        "update_time": ""
    },
    {
        "id": "108",
        "parentId": "104",
        "appName": "双向板",
        "url": "1a.asp",
        "icon": "双向板",
        "pic": "选",
        "state": "1",
        "description": "根据结构静力计算手册求解简支和固端边界矩形板内力，并给出配筋值、裂缝及挠度。",
        "rule": "静力手册",
        "keywords": "双向板,弹性板,均布荷载",
        "create_time": "1/8/2015 00:00:00",
        "update_time": ""
    },
    {
        "id": "109",
        "parentId": "104",
        "appName": "风荷载标准值",
        "url": "125.asp",
        "icon": "风荷载",
        "pic": "",
        "state": "1",
        "description": "根据《荷规》8.1节，对外形、质量、刚度变化比较均匀的高层结构或高耸结构，根据所选地的基本风压及建筑物情况计算主要受力结构的风荷载标准值。",
        "rule": "GB 50009-2012",
        "keywords": "风荷载体形系数,风荷载高度变化系数,考虑地形条件的修正系数,外形,质量,刚度,高层结构,高耸结构,基本风压,受力结构的风荷载标准值,建筑结构荷载规范,GB 50009-2012,8.1",
        "create_time": "1/8/2015 00:00:00",
        "update_time": ""
    },
    {
        "id": "11",
        "parentId": "1",
        "appName": "车库最优梁截面",
        "url": "468a.asp",
        "icon": "车库最优梁截面",
        "pic": "选",
        "state": "1",
        "description": "根据车库次梁布置方式自动求解给定梁宽、梁高范围梁配筋，输出钢筋排数、裂缝及配筋率，用于初步设计确定截面。",
        "rule": "GB 50010-2010",
        "keywords": "梁配筋,车库梁截面优选,最优梁截面计算,混凝土结构设计规范,GB 50010-2010,6.2",
        "create_time": "6/11/2015 00:00:00",
        "update_time": ""
    },
    {
        "id": "110",
        "parentId": "104",
        "appName": "维护结构风荷载标准值",
        "url": "126.asp",
        "icon": "风荷载",
        "pic": "",
        "state": "1",
        "description": "根据《荷规》8.1.1条对围护结构的风荷载进行计算。",
        "rule": "GB 50009-2012",
        "keywords": "风荷载标准值,围护结构,围护结构风荷载,基本风压,50年重现期,建筑结构荷载规范,GB 50009-2012,8.1",
        "create_time": "1/8/2015 00:00:00",
        "update_time": ""
    },
    {
        "id": "111",
        "parentId": "104",
        "appName": "抗风柱风荷载设计值",
        "url": "127.asp",
        "icon": "风荷载",
        "pic": "gif\\kfz.jpg",
        "state": "1",
        "description": "根据《荷规》8.1.1条对抗风柱风荷载进行计算。",
        "rule": "GB 50009-2012",
        "keywords": "风荷载设计值,抗风柱,迎风面风荷载体型系数,截面体型系数,建筑结构荷载规范,GB 50009-2012,8.1.1",
        "create_time": "1/8/2015 00:00:00",
        "update_time": ""
    },
    {
        "id": "112",
        "parentId": "",
        "appName": "常用数据表",
        "url": "",
        "icon": "",
        "pic": "",
        "state": "1",
        "description": "",
        "rule": "",
        "keywords": "",
        "create_time": "",
        "update_time": ""
    },
    {
        "id": "113",
        "parentId": "112",
        "appName": "钢筋截面面积",
        "url": "132.asp",
        "icon": "常用数据表",
        "pic": "",
        "state": "1",
        "description": "钢筋的计算截面面积及理论重量",
        "rule": "GB 50010-2010",
        "keywords": "单根钢筋面积表,多根钢筋面积",
        "create_time": "10/3/2012 00:00:00",
        "update_time": ""
    },
    {
        "id": "114",
        "parentId": "112",
        "appName": "板钢筋面积",
        "url": "133.asp",
        "icon": "常用数据表",
        "pic": "",
        "state": "1",
        "description": "每米板宽内的钢筋截面面积查询",
        "rule": "GB 50010-2010",
        "keywords": "板钢筋面积,板筋面积",
        "create_time": "10/3/2012 00:00:00",
        "update_time": ""
    },
    {
        "id": "115",
        "parentId": "112",
        "appName": "梁单排钢筋数",
        "url": "134.asp",
        "icon": "常用数据表",
        "pic": "",
        "state": "1",
        "description": "梁内单排钢筋最大根数",
        "rule": "GB 50010-2010",
        "keywords": "单排钢筋数量,第二排钢筋数量",
        "create_time": "20/8/2015 00:00:00",
        "update_time": ""
    },
    {
        "id": "116",
        "parentId": "112",
        "appName": "梁腰筋表",
        "url": "135.asp",
        "icon": "常用数据表",
        "pic": "",
        "state": "1",
        "description": "梁腹板高度范围内的纵向构造钢筋，即腰筋",
        "rule": "GB 50010-2010",
        "keywords": "腰筋表",
        "create_time": "20/8/2015 00:00:00",
        "update_time": ""
    },
    {
        "id": "117",
        "parentId": "112",
        "appName": "型钢截面特性",
        "url": "54.asp",
        "icon": "常用数据表",
        "pic": "",
        "state": "1",
        "description": "适用于查询常用型钢截面特性",
        "rule": "GB 50017-2003",
        "keywords": "",
        "create_time": "25/4/2016 00:00:00",
        "update_time": ""
    },
    {
        "id": "118",
        "parentId": "112",
        "appName": "梁柱箍筋表",
        "url": "136.asp",
        "icon": "常用数据表",
        "pic": "",
        "state": "1",
        "description": "用于与PKPM箍筋输出结果查表配筋",
        "rule": "GB 50010-2010",
        "keywords": "箍筋表,箍筋换算表",
        "create_time": "25/8/2015 00:00:00",
        "update_time": ""
    },
    {
        "id": "119",
        "parentId": "",
        "appName": "其他",
        "url": "",
        "icon": "",
        "pic": "",
        "state": "1",
        "description": "",
        "rule": "",
        "keywords": "",
        "create_time": "",
        "update_time": ""
    },
    {
        "id": "12",
        "parentId": "1",
        "appName": "矩形水池计算",
        "url": "469.asp",
        "icon": "NO",
        "pic": "",
        "state": "1",
        "description": "通过板壳有限元计算带顶板或不带顶板矩形水池强度、裂缝、配筋、挠度计算",
        "rule": "GB 50010-2010",
        "keywords": "水池",
        "create_time": "23/4/2023 00:00:00",
        "update_time": ""
    },
    {
        "id": "120",
        "parentId": "119",
        "appName": "注册结构师复习考试",
        "url": "502.asp",
        "icon": "NO",
        "pic": "",
        "state": "1",
        "description": "2020年2月4日起，平均每天更新一条历年试题，支持常规浏览器及移动端Safari浏览器环境正常使用；在答题区作答可用鼠标拖入公式区公式代替手写",
        "rule": "",
        "keywords": "",
        "create_time": "3/2/2020 00:00:00",
        "update_time": ""
    },
    {
        "id": "121",
        "parentId": "119",
        "appName": "图启家装ToolkipHouse",
        "url": "503.asp",
        "icon": "NO",
        "pic": "",
        "state": "1",
        "description": "",
        "rule": "",
        "keywords": "",
        "create_time": "6/2/2020 00:00:00",
        "update_time": ""
    },
    {
        "id": "122",
        "parentId": "119",
        "appName": "我学规范",
        "url": "508.asp",
        "icon": "NO",
        "pic": "",
        "state": "1",
        "description": "",
        "rule": "",
        "keywords": "",
        "create_time": "21/12/2020 00:00:00",
        "update_time": ""
    },
    {
        "id": "13",
        "parentId": "1",
        "appName": "单层外墙",
        "url": "163a.asp",
        "icon": "单层外墙",
        "pic": "选",
        "state": "1",
        "description": "单层外墙程序实现对内力、强度、裂缝的集成计算，使用有限元内核进行内力计算及图形显示。",
        "rule": "GB 50010-2010",
        "keywords": "地下室外墙,外墙,单层外墙,外墙裂缝控制,悬臂外墙",
        "create_time": "1/12/2019 00:00:00",
        "update_time": ""
    },
    {
        "id": "14",
        "parentId": "1",
        "appName": "双层外墙",
        "url": "165a.asp",
        "icon": "双层外墙",
        "pic": "选",
        "state": "1",
        "description": "双层外墙程序实现对内力、强度、裂缝的集成计算，使用有限元内核进行内力计算及图形显示。",
        "rule": "GB 50010-2010",
        "keywords": "地下室外墙,外墙,双层外墙,外墙裂缝控制,悬臂外墙",
        "create_time": "1/12/2019 00:00:00",
        "update_time": ""
    },
    {
        "id": "15",
        "parentId": "1",
        "appName": "坡道外墙",
        "url": "167a.asp",
        "icon": "坡道外墙",
        "pic": "选",
        "state": "1",
        "description": "坡道外墙程序实现对坡道顶板、墙板、底板内力、强度、裂缝的集成计算，使用有限元内核进行内力计算及图形显示。",
        "rule": "GB 50010-2010",
        "keywords": "坡道外墙,坡道计算,外墙裂缝控制,悬臂坡道墙",
        "create_time": "1/12/2019 00:00:00",
        "update_time": ""
    },
    {
        "id": "16",
        "parentId": "1",
        "appName": "无梁楼盖",
        "url": "139.asp",
        "icon": "无梁楼盖",
        "pic": "pic\\zm3.gif",
        "state": "1",
        "description": "根据《混规》6.5.1及附录F编制，可用于计算不配置箍筋或弯起钢筋的板柱冲切，柱帽形式定位常用的底托板式，进行冲切承载力计算。",
        "rule": "GB 50010-2010",
        "keywords": "板柱节点计算,双向不平衡弯矩,等效集中反力设计值,最大剪应力作用点,混凝土结构设计规范,GB50010-2010,6.5.1,附录F",
        "create_time": "1/8/2015 00:00:00",
        "update_time": ""
    },
    {
        "id": "17",
        "parentId": "",
        "appName": "超限及高层",
        "url": "",
        "icon": "",
        "pic": "",
        "state": "1",
        "description": "",
        "rule": "",
        "keywords": "",
        "create_time": "",
        "update_time": ""
    },
    {
        "id": "18",
        "parentId": "17",
        "appName": "楼盖舒适度",
        "url": "227.asp",
        "icon": "楼盖舒适度",
        "pic": "",
        "state": "1",
        "description": "根据《高规》3.7.7及附录A要求，舒适度分析的主要有三项工作，其一是计算楼盖竖向振动频率，一般采用有限元软件放大刚度后进行模态分析；其二是根据A.0.1采用时程分析方法进行楼盖结构的竖向振动加速度计算；其三是根据A.0.2进行人行走引起的楼盖振动峰值加速度计算，本程序即根据第三项要求编制。",
        "rule": "JGJ 3-2010",
        "keywords": "楼盖结构的竖向振动频率,楼盖结构竖向振动加速度,  人员活动环境,峰值加速度限值,高层建筑混凝土结构技术规程,JGJ3-2010 备案号J186-2010,3.7.7,附录A",
        "create_time": "1/8/2015 00:00:00",
        "update_time": ""
    },
    {
        "id": "19",
        "parentId": "17",
        "appName": "墙稳定性验算",
        "url": "228.asp",
        "icon": "墙稳定性验算",
        "pic": "选",
        "state": "1",
        "description": "根据《高规》附录D要求，对单片墙、T形、L形、槽形、工字形剪力墙翼缘和腹板进行墙肢稳定性计算；当填入全截面几何参数并勾选进行整体稳定性计算时，程序给出计算结果的同时也对是否需要进行整体稳定计算给出建议。",
        "rule": "JGJ 3-2010",
        "keywords": "剪力墙的截面厚度,附录D墙体稳定性验算,剪力墙墙肢稳定,剪力墙的整体稳定,高层建筑混凝土结构技术规程,JGJ3-2010,备案号J186-2010,附录D",
        "create_time": "1/8/2015 00:00:00",
        "update_time": ""
    },
    {
        "id": "2",
        "parentId": "1",
        "appName": "独立基础",
        "url": "170.asp",
        "icon": "独立基础",
        "pic": "选",
        "state": "1",
        "description": "根据《地基》8.2计算锥形基础或阶梯形基础受冲切、受剪、受弯、局部受压计算。",
        "rule": "GB 50007-2011",
        "keywords": "独立基础,锥形基础,阶梯形基础,扩展基础,建筑地基基础设计规范,GB50007-2011,5.2",
        "create_time": "14/10/2015 00:00:00",
        "update_time": ""
    },
    {
        "id": "20",
        "parentId": "17",
        "appName": "墙施工缝验算",
        "url": "166.asp",
        "icon": "墙施工缝验算",
        "pic": "",
        "state": "1",
        "description": "根据《高规》7.2.12抗震等级为一级的剪力墙需要进行水平施工缝的抗滑移计算，通过墙体的竖向钢筋、边缘构件中配置的竖向钢筋及附加竖向插筋（俗称短筋）提高剪力墙水平施工缝处的受剪承载力，本程序用于验算其承载力是否满足要求。",
        "rule": "JGJ 3-2010",
        "keywords": "剪力墙的水平施工缝,附加竖向插筋,短筋,水平施工缝的抗滑移,剪力墙的竖向配筋率,高层建筑混凝土结构技术规程,JGJ3-2010,备案号J186-2010,7.2.12",
        "create_time": "1/8/2015 00:00:00",
        "update_time": ""
    },
    {
        "id": "21",
        "parentId": "17",
        "appName": "选波",
        "url": "5.asp",
        "icon": "选波",
        "pic": "",
        "state": "1",
        "description": "根据《高规》4.3.5及条文说明，并参考国内专家论述根据地震动三要素的要求，即频谱特性、有效峰值和持续时间相似的要求实现太平洋地震网数据自动选波，本程序计算时间较长，最终结果通过Email发送至用户。",
        "rule": "JGJ 3-2010",
        "keywords": "在统计意义上的相符,楼盖结构竖向振动加速度,地震加速度时程曲线,地震影响系数曲线,即频谱特性,有效峰值,持续时间,相似,高层建筑混凝土结构技术规程,JGJ3-2010 备案号J186-2010,4.3.5",
        "create_time": "1/8/2015 00:00:00",
        "update_time": ""
    },
    {
        "id": "22",
        "parentId": "17",
        "appName": "剪力墙配筋助手",
        "url": "120.asp",
        "icon": "剪力墙配筋助手",
        "pic": "选",
        "state": "1",
        "description": "根据《高规》7.2.15、7.2.16，程序计算约束边缘构件或构造边缘构件配筋率、体积配箍率，并与限值进行比较。",
        "rule": "",
        "keywords": "约束边缘构件,构造边缘构件,体积配箍率,暗柱,端柱,翼墙,高层建筑混凝土结构技术规程,JGJ 3-2010 备案号JGJ 186-2010,7.1.15,7.2.16",
        "create_time": "1/8/2015 00:00:00",
        "update_time": ""
    },
    {
        "id": "23",
        "parentId": "17",
        "appName": "梁上开洞",
        "url": "466.asp",
        "icon": "梁上开洞",
        "pic": "",
        "state": "1",
        "description": "本程序对梁上开方洞、圆洞进行开洞尺寸构造判断及加固钢筋的计算。",
        "rule": "砼构造手册",
        "keywords": "梁上开洞计算,留洞,方洞,圆洞,受压弦杆,受拉弦杆,梁上开洞构造,混凝土构造设计手册",
        "create_time": "1/8/2015 00:00:00",
        "update_time": ""
    },
    {
        "id": "24",
        "parentId": "17",
        "appName": "加腋板",
        "url": "229a.asp",
        "icon": "加腋板",
        "pic": "",
        "state": "1",
        "description": "进行混凝土加腋板计算，程序实现加腋板板顶、板底弯矩、配筋、裂缝的综合计算。",
        "rule": "GB 50010-2010",
        "keywords": "加腋大板计算,加腋板,净高控制,板优化",
        "create_time": "1/1/2016 00:00:00",
        "update_time": ""
    },
    {
        "id": "25",
        "parentId": "17",
        "appName": "钢板剪力墙",
        "url": "84.asp",
        "icon": "钢板剪力墙",
        "pic": "",
        "state": "1",
        "description": "根据《高规》钢板剪力墙抗剪截面及承载力计算",
        "rule": "JGJ 3-2010",
        "keywords": "钢板剪力墙",
        "create_time": "22/2/2023 00:00:00",
        "update_time": ""
    },
    {
        "id": "26",
        "parentId": "17",
        "appName": "十字形型钢混凝土柱",
        "url": "86.asp",
        "icon": "型钢混凝土",
        "pic": "",
        "state": "1",
        "description": "根据《组合结构设计规范》对常用的十字形型钢混凝土柱的抗弯、抗剪、截面进行验算",
        "rule": "JGJ 138-2016",
        "keywords": "型钢混凝土",
        "create_time": "1/3/2023 00:00:00",
        "update_time": ""
    },
    {
        "id": "27",
        "parentId": "",
        "appName": "混凝土构件",
        "url": "",
        "icon": "",
        "pic": "",
        "state": "1",
        "description": "",
        "rule": "",
        "keywords": "",
        "create_time": "",
        "update_time": ""
    },
    {
        "id": "28",
        "parentId": "27",
        "appName": "弯剪扭构件",
        "url": "169.asp",
        "icon": "弯剪扭构件",
        "pic": "pic\\jx.gif",
        "state": "1",
        "description": "根据《混规》6.4条对弯剪扭构件进行正截面抗弯、斜截面抗剪扭曲截面承载力进行及计算.",
        "rule": "GB 50010-2010",
        "keywords": "扭矩,弯剪扭构件,弯剪扭构件正截面,抗弯,斜截面抗剪,扭曲计算,混凝土结构设计规范,GB 50010-2010,6.4",
        "create_time": "14/10/2015 00:00:00",
        "update_time": ""
    },
    {
        "id": "29",
        "parentId": "27",
        "appName": "裂缝宽度",
        "url": "225.asp",
        "icon": "裂缝宽度",
        "pic": "pic\\jx.gif",
        "state": "1",
        "description": "根据《混规》7.1编制，可用于计算矩形截面在三级裂缝控制等级时按荷载准永久组合并考虑长期作用影响下构件的最大裂缝宽度。",
        "rule": "GB 50010-2010",
        "keywords": "正常使用极限状态验算,裂缝控制验算,裂缝控制等级, 最大裂缝宽度,荷载准永久组合,考虑长期作用,混凝土结构设计规范,GB50010-2010,7.1",
        "create_time": "1/8/2015 00:00:00",
        "update_time": ""
    },
    {
        "id": "3",
        "parentId": "1",
        "appName": "抗浮锚杆",
        "url": "19.asp",
        "icon": "锚杆",
        "pic": "",
        "state": "1",
        "description": "根据《技术统一手册》按照摩擦力计算锚杆的抗拔承载力",
        "rule": "GB 50007-2011",
        "keywords": "抗浮锚杆,抗拔锚杆,土层锚杆",
        "create_time": "6/11/2015 00:00:00",
        "update_time": ""
    },
    {
        "id": "30",
        "parentId": "27",
        "appName": "局部受压",
        "url": "47.asp",
        "icon": "局部受压",
        "pic": "选",
        "state": "1",
        "description": "根据《混规》6.6编制，可根据各种局部受压情况进行局压截面计算及配置间接钢筋承载力验算。",
        "rule": "GB 50010-2010",
        "keywords": "局部受压承载力,方格网式间接钢筋,螺旋式间接钢筋,混凝土结构设计规范,GB50010-2010,6.6",
        "create_time": "1/8/2015 00:00:00",
        "update_time": ""
    },
    {
        "id": "31",
        "parentId": "27",
        "appName": "吊筋",
        "url": "119.asp",
        "icon": "吊筋",
        "pic": "pic/fujia.gif",
        "state": "1",
        "description": "根据《混规》9.2.11编制，可用于验算在集中荷载下的附加吊筋配筋量。",
        "rule": "GB 50010-2010",
        "keywords": "局部配筋,附加横向钢筋,附加吊筋,附加箍筋,混凝土结构设计规范,GB50010-2010,9.2.11",
        "create_time": "1/8/2015 00:00:00",
        "update_time": ""
    },
    {
        "id": "32",
        "parentId": "27",
        "appName": "受弯构件",
        "url": "6.asp",
        "icon": "受弯构件",
        "pic": "选",
        "state": "1",
        "description": "根据《混规》6.2.10编制，可用于计算矩形截面、T型截面受弯构件的受拉区钢筋，可选择单筋截面和双筋截面计算。",
        "rule": "GB 50010-2010",
        "keywords": "弯矩配筋,受压钢筋,单筋截面、双筋截面,第一类截面、第二类截面,相对受压区高度限值,混凝土结构设计规范,GB50010-2010,6.2.10",
        "create_time": "1/8/2015 00:00:00",
        "update_time": ""
    },
    {
        "id": "33",
        "parentId": "27",
        "appName": "受压构件",
        "url": "7.asp",
        "icon": "受压构件",
        "pic": "",
        "state": "1",
        "description": "根据《混规》6.2.16及附录E编制，可用于计算矩形截面、圆形截面轴心受压及对称配筋的偏心受压构件。",
        "rule": "GB 50010-2010",
        "keywords": "正截面受压承载力计算,附录E任意截面,圆形及环形构件,矩形截面对称配筋,受压柱的计算长度,混凝土结构设计规范,GB50010-2010,6.2.16",
        "create_time": "1/8/2015 00:00:00",
        "update_time": ""
    },
    {
        "id": "34",
        "parentId": "27",
        "appName": "受拉构件",
        "url": "42.asp",
        "icon": "受拉构件",
        "pic": "",
        "state": "1",
        "description": "根据《混规》6.2.22~23编制，可用于计算矩形截面对称配筋的轴心或偏心受拉构件，求每侧竖向钢筋配筋量。",
        "rule": "GB 50010-2010",
        "keywords": "正截面受拉承载力,轴心受拉,小偏心受拉,大偏心受拉构件,混凝土结构设计规范,GB50010-2010,6.2.22，6.2.23",
        "create_time": "1/8/2015 00:00:00",
        "update_time": ""
    },
    {
        "id": "35",
        "parentId": "27",
        "appName": "受剪构件",
        "url": "43.asp",
        "icon": "受剪构件",
        "pic": "选",
        "state": "1",
        "description": "根据《混规》6.3编制，可用于计算矩形截面、T形截面的受剪截面是否满足要求及配箍筋量。",
        "rule": "GB 50010-2010",
        "keywords": "斜截面承载力计算,受剪截面,一般类受弯构件,不配置箍筋及弯起钢筋,混凝土结构设计规范,GB50010-2010,6.3",
        "create_time": "1/8/2015 00:00:00",
        "update_time": ""
    },
    {
        "id": "36",
        "parentId": "27",
        "appName": "牛腿",
        "url": "3.asp",
        "icon": "牛腿",
        "pic": "pic\\nt.gif",
        "state": "1",
        "description": "根据《混规》9.3.10~9.3.13编制，可用于计算牛腿裂缝控制要求及牛腿的配筋。",
        "rule": "GB 50010-2010",
        "keywords": "牛腿的裂缝控制,裂缝控制验算,裂缝控制系数,牛腿的剪跨比,混凝土结构设计规范,GB50010-2010,9.3.10,9.3.11,9.3.12,9.3.13",
        "create_time": "1/8/2015 00:00:00",
        "update_time": ""
    },
    {
        "id": "37",
        "parentId": "27",
        "appName": "预埋件",
        "url": "4.asp",
        "icon": "预埋件",
        "pic": "pic\\ym.gif",
        "state": "1",
        "description": "根据《混规》9.7.2编制，可用于计算受剪、受拉或受压、受弯作用下的预埋件配筋，并且根据预埋件构造规定对设计尺寸进行检查。",
        "rule": "GB 50010-2010",
        "keywords": "预埋件及连接件,锚板,锚筋,穿孔塞焊,防止锚板弯曲变形措施,受拉和受弯预埋件,受剪预埋件,混凝土结构设计规范,GB50010-2010,9.7.2",
        "create_time": "1/8/2015 00:00:00",
        "update_time": ""
    },
    {
        "id": "38",
        "parentId": "27",
        "appName": "楼梯",
        "url": "103a.asp",
        "icon": "楼梯",
        "pic": "选",
        "state": "1",
        "description": "根据荷载规范及混规土规范进行编制，可用于计算AT、BT、CT、DT类型楼梯，自动计算强度、裂缝、挠度。",
        "rule": "GB 50010-2010",
        "keywords": "板室楼梯,AT,BT,CT,DT,楼梯挠度",
        "create_time": "1/8/2015 00:00:00",
        "update_time": ""
    },
    {
        "id": "39",
        "parentId": "27",
        "appName": "折梁内折角增设箍筋",
        "url": "80.asp",
        "icon": "折梁内折角增设箍筋",
        "pic": "pic\\zlnzj.gif",
        "state": "1",
        "description": "根据《混规》9.2.12求解折梁内折角处的箍筋量和配置间距",
        "rule": "GB 50010-2010",
        "keywords": "折梁,内折角",
        "create_time": "30/1/2023 00:00:00",
        "update_time": ""
    },
    {
        "id": "4",
        "parentId": "1",
        "appName": "桩水平力",
        "url": "464.asp",
        "icon": "桩水平力",
        "pic": "",
        "state": "1",
        "description": "根据《桩基》5.7对常用类型桩进行单桩水平承载力特征值计算。",
        "rule": "JGJ 94-2008",
        "keywords": "单桩基础水平承载力,桩的水平变形系数,桩侧土水平抗力系数,地基土类别,桩顶水平位移,建筑桩基技术规",
        "create_time": "1/8/2015 00:00:00",
        "update_time": ""
    },
    {
        "id": "40",
        "parentId": "",
        "appName": "钢结构构件",
        "url": "",
        "icon": "",
        "pic": "",
        "state": "1",
        "description": "",
        "rule": "",
        "keywords": "",
        "create_time": "",
        "update_time": ""
    },
    {
        "id": "41",
        "parentId": "40",
        "appName": "桁架节点板",
        "url": "221.asp",
        "icon": "桁架节点板",
        "pic": "选",
        "state": "1",
        "description": "根据《钢规》7.5及附录F，计算有竖腹杆和无竖腹杆时桁架节点板的构造要求、强度及稳定性。",
        "rule": "GB 50017-2017",
        "keywords": "连接节点处板件的计算,桁架节点板,斜腹杆、竖腹杆、弦杆,节点板失稳时的屈折线,钢结构设计规范,GB50017-2003,7.5,附录F",
        "create_time": "1/8/2015 00:00:00",
        "update_time": ""
    },
    {
        "id": "42",
        "parentId": "40",
        "appName": "檩条",
        "url": "465a.asp",
        "icon": "檩条",
        "pic": "",
        "state": "1",
        "description": "根据《门规》6.3及附录E，计算檩条强度及风吸力作用下的稳定性，内含常用C、Z型冷弯薄壁型钢库。",
        "rule": "CECS 102:2002",
        "keywords": "卷边槽形冷弯薄壁型钢,Z形冷弯薄壁型钢,直拉条,斜拉条、撑杆,隅撑、锲形垫块,门式刚架轻型房屋钢结构技术规程（2012年版）,CECS 102:2002,6.3,附录E",
        "create_time": "1/8/2015 00:00:00",
        "update_time": ""
    },
    {
        "id": "43",
        "parentId": "40",
        "appName": "吊车荷载",
        "url": "121.asp",
        "icon": "吊车",
        "pic": "选",
        "state": "1",
        "description": "根据《荷规》6及《钢手册》编制,根据吊车参数计算竖向组合和水平荷载,此荷载用于对结构的整体计算输入。",
        "rule": "GB 50017-2017",
        "keywords": "吊车工作制级别,吊车起重量,最大轮压 最小轮压,建筑结构荷载规范,钢结构设计手册",
        "create_time": "1/8/2015 00:00:00",
        "update_time": ""
    },
    {
        "id": "44",
        "parentId": "40",
        "appName": "螺栓计算",
        "url": "463.asp",
        "icon": "螺栓",
        "pic": "选",
        "state": "1",
        "description": "根据《钢规》7.2，程序计算普通螺栓、锚栓、高强螺栓在弯矩、轴力、剪力作用下的承载力是否满足强度要求。",
        "rule": "GB 50017-2017",
        "keywords": "紧固件,螺栓,铆钉,连接,普通螺栓,锚栓,高强螺栓摩擦型连接,高强螺栓承压型连接,钢结构设计规范,GB 50017-2003,7.2",
        "create_time": "1/8/2015 00:00:00",
        "update_time": ""
    },
    {
        "id": "45",
        "parentId": "40",
        "appName": "角焊缝",
        "url": "64.asp",
        "icon": "角焊缝",
        "pic": "选",
        "state": "1",
        "description": "根据《钢手册》及《钢规》7.1.3，计算牛腿、垂直连接等常用各种角焊缝连接的强度。",
        "rule": "钢结构设计手册",
        "keywords": "直角角焊缝,正面角焊缝,侧面角焊缝,钢结构牛腿,焊缝有效截面,钢结构设计手册,JGJ 3-2010,备案号J 186-2010,7.1.3",
        "create_time": "1/8/2015 00:00:00",
        "update_time": ""
    },
    {
        "id": "46",
        "parentId": "40",
        "appName": "加劲肋",
        "url": "98.asp",
        "icon": "加劲肋",
        "pic": "选",
        "state": "1",
        "description": "根据《钢规》4.3，分别对仅配置横向加劲肋、配置横向、纵向加劲肋及设置横向、纵向、短加劲肋进行腹板稳定性计算，另外考虑腹板屈曲后强度进行抗弯和抗剪承载力计算，用户可根据规范4.3.1要求选择需要的计算项。",
        "rule": "GB 50017-2017",
        "keywords": "局部稳定,腹板屈曲后强度,加劲肋,横向加劲肋,纵向加劲肋,横向纵向短加劲肋,腹板稳定性计算,腹板屈曲后强度,抗弯和抗剪承载力计算，组合梁,钢结构设计规范,JGJ 50017-2003,4.3",
        "create_time": "1/8/2015 00:00:00",
        "update_time": ""
    },
    {
        "id": "47",
        "parentId": "40",
        "appName": "钢与混凝土组合梁",
        "url": "81.asp",
        "icon": "钢与混凝土组合梁",
        "pic": "选",
        "state": "1",
        "description": "根据《钢标》14章进行组合梁承载力、挠度及栓钉等计算",
        "rule": "GB 50017-2017",
        "keywords": "组合梁",
        "create_time": "15/2/2023 00:00:00",
        "update_time": ""
    },
    {
        "id": "48",
        "parentId": "40",
        "appName": "圆钢管连接平面节点",
        "url": "82.asp",
        "icon": "圆钢管连接平面节点",
        "pic": "选",
        "state": "1",
        "description": "根据《钢标》对平面圆管节点计算",
        "rule": "GB 50017-2017",
        "keywords": "圆管连接",
        "create_time": "16/2/2023 00:00:00",
        "update_time": ""
    },
    {
        "id": "49",
        "parentId": "40",
        "appName": "工字钢梁受弯",
        "url": "79.asp",
        "icon": "工字钢梁受弯",
        "pic": "pic\\ztwd.gif",
        "state": "1",
        "description": "根据《钢规》5.2及附录B，计算非对称或堆成工字形截面受弯矩作用下的强度及整体稳定性。",
        "rule": "GB 50017-2017",
        "keywords": "整体稳定性,截面塑性发展系数,受拉翼缘,梁的整体稳定系数,钢结构设计规范,GB50017-2003,5.2,附录B",
        "create_time": "1/8/2015 00:00:00",
        "update_time": ""
    },
    {
        "id": "5",
        "parentId": "1",
        "appName": "承台",
        "url": "405.asp",
        "icon": "承台",
        "pic": "",
        "state": "1",
        "description": "本程序适合设计使用要求进行编制，主要功能是对2~9桩承台按桩承载力用足计算其截面高度，待用户根据计算结果对承台高度归并并填入后完成最终计算书 。",
        "rule": "JGJ 94-2008",
        "keywords": "柱下桩基承台,承台兼做反柱帽,柱下板带,深受弯矩构件,二至九桩承台,承台高度归并,建筑桩基技术规范,JGJ 94-2008",
        "create_time": "1/8/2015 00:00:00",
        "update_time": ""
    },
    {
        "id": "50",
        "parentId": "40",
        "appName": "角钢焊接连接",
        "url": "74.asp",
        "icon": "角钢焊接连接",
        "pic": "选",
        "state": "1",
        "description": "用于计算角钢与钢板连接的角焊缝，包括两面侧焊、L型围焊、三面围焊",
        "rule": "GB 50017-2017",
        "keywords": "角钢焊接",
        "create_time": "26/4/2015 00:00:00",
        "update_time": ""
    },
    {
        "id": "51",
        "parentId": "40",
        "appName": "钢梁受弯受剪",
        "url": "78.asp",
        "icon": "钢梁受弯受剪",
        "pic": "",
        "state": "1",
        "description": "用于计算常用型钢受弯、受剪、整体稳定性计算",
        "rule": "GB 50017-2017",
        "keywords": "钢梁受弯",
        "create_time": "27/4/2015 00:00:00",
        "update_time": ""
    },
    {
        "id": "52",
        "parentId": "40",
        "appName": "销轴耳板计算",
        "url": "65.asp",
        "icon": "销轴耳板计算",
        "pic": "pic\\xzeb.jpg",
        "state": "1",
        "description": "根据《钢规》及常规方式整理计算",
        "rule": "GB 50017-2017",
        "keywords": "销轴耳板",
        "create_time": "1/12/2017 00:00:00",
        "update_time": ""
    },
    {
        "id": "53",
        "parentId": "40",
        "appName": "埋入式柱脚埋置深度验算",
        "url": "83.asp",
        "icon": "埋入式柱脚",
        "pic": "",
        "state": "1",
        "description": "根据《钢标》进行埋入式柱脚埋入深度计算",
        "rule": "GB 50017-2017",
        "keywords": "柱脚",
        "create_time": "16/2/2023 00:00:00",
        "update_time": ""
    },
    {
        "id": "54",
        "parentId": "40",
        "appName": "组合楼板",
        "url": "85.asp",
        "icon": "NO",
        "pic": "选",
        "state": "1",
        "description": "根据《组合结构设计规范》第13章节全部内容，计算组合楼板抗弯、抗剪、裂缝、挠度承载力，适用于底模采用压型钢板浇注钢筋混凝土的楼板充分利用压型钢板承载力的计算需求",
        "rule": "JGJ 138-2016",
        "keywords": "组合楼板,压型钢板",
        "create_time": "22/2/2023 00:00:00",
        "update_time": ""
    },
    {
        "id": "55",
        "parentId": "",
        "appName": "加固及改造",
        "url": "",
        "icon": "",
        "pic": "",
        "state": "1",
        "description": "",
        "rule": "",
        "keywords": "",
        "create_time": "",
        "update_time": ""
    },
    {
        "id": "56",
        "parentId": "55",
        "appName": "化学锚栓",
        "url": "122.asp",
        "icon": "化学锚栓",
        "pic": "选",
        "state": "1",
        "description": "根据《钢规》7.2，《加固规范》第16章，程序计算锚栓在弯矩、轴力、剪力作用下的承载力是否满足强度要求。",
        "rule": "GB 50367-2013",
        "keywords": "普通螺栓,锚栓,锚栓钢材承载力验算,锚栓基材混凝土承载力验算,钢结构设计规范,GB 50017-2003,7.2,混凝土结构加固设计规范,GB 50367-2013,16章",
        "create_time": "1/8/2015 00:00:00",
        "update_time": ""
    },
    {
        "id": "57",
        "parentId": "55",
        "appName": "粘钢法加固梁",
        "url": "112.asp",
        "icon": "粘钢法加固梁",
        "pic": "选",
        "state": "1",
        "description": "根据《加固》9.2节及9.3节，采用受拉区粘贴钢板对梁进行抗弯加固,采用箍板对梁进行抗剪承载力加固。",
        "rule": "GB 50367-2013",
        "keywords": "粘贴钢板加固法,受弯构件正截面加固计算,受弯构件斜截面加固计算,受拉区粘贴钢板,梁抗弯加固,箍板,梁行抗剪承载力加固,混凝土结构加固设计规范,GB 50367-2013,9.2,9.3",
        "create_time": "1/8/2015 00:00:00",
        "update_time": ""
    },
    {
        "id": "58",
        "parentId": "55",
        "appName": "加大截面加固梁",
        "url": "137.asp",
        "icon": "加大截面加固梁",
        "pic": "选",
        "state": "1",
        "description": "根据《加固》5.2节及5.3节，对底部增大截面及上下同时增大截面加固进行抗弯钢筋的计算，另外采用U型箍或三面围套箍筋进行抗剪承载力计算。",
        "rule": "GB 50367-2013",
        "keywords": "增大截面加固法,受弯构件正截面加固计算,受弯构件斜截面加固计算,底部增大截面加固,抗弯钢筋计算,U型箍,三面围套箍筋,抗剪承载力计算,混凝土结构加固设计规范,GB 50367-2013,5.2,5.3",
        "create_time": "1/8/2015 00:00:00",
        "update_time": ""
    },
    {
        "id": "59",
        "parentId": "55",
        "appName": "植筋深度",
        "url": "149.asp",
        "icon": "植筋深度",
        "pic": "",
        "state": "1",
        "description": "根据《加固》15节，根据植筋的环境、基面情况、直径、强度计算植筋的锚固长度。",
        "rule": "GB 50367-2013",
        "keywords": "植筋技术,混凝土劈裂影响,植筋用胶粘剂的粘结强度,植筋锚固长度,混凝土结构加固设计规范,GB 50367-2013,15",
        "create_time": "1/8/2015 00:00:00",
        "update_time": ""
    },
    {
        "id": "6",
        "parentId": "1",
        "appName": "桩承载力计算",
        "url": "411.asp",
        "icon": "桩承载力",
        "pic": "",
        "state": "1",
        "description": "根据《桩》5.3编制，可用于计算管桩、空心方桩、预制方桩、灌注桩四种常用桩型的竖向承载力和抗拔承载力。",
        "rule": "JGJ 94-2008",
        "keywords": "桩型选择,经验参数法,桩端土塞效应,桩端、桩侧复式注浆,单桩竖向极限承载力标准值,单桩竖向承载力特征值,计算管桩,空心方桩,预制方桩,灌注桩,竖向承载力,抗拔承载力,建筑桩基技术规范,JGJ 94-2008,5.3",
        "create_time": "1/8/2015 00:00:00",
        "update_time": ""
    },
    {
        "id": "60",
        "parentId": "55",
        "appName": "碳纤维加固梁",
        "url": "151.asp",
        "icon": "碳纤维加固梁",
        "pic": "选",
        "state": "1",
        "description": "根据《加固》10.2节及10.3节，采用纤维布或纤维板对梁进行加固,计算其加固后的抗弯及抗剪承载力是否满足要求。",
        "rule": "GB 50367-2013",
        "keywords": "粘贴纤维复合材加固法,受弯构件正截面加固计算,受弯构件斜截面加固计算,纤维布,纤维板,梁加固,抗弯抗剪承载力,混凝土结构加固设计规范,GB 50367-2013,10.2,10.3",
        "create_time": "1/8/2015 00:00:00",
        "update_time": ""
    },
    {
        "id": "61",
        "parentId": "55",
        "appName": "柱加大截面",
        "url": "152.asp",
        "icon": "加大截面加固柱",
        "pic": "选",
        "state": "1",
        "description": "根据《加固》5.4.2节，采用加大截面法对单侧(受压区或受拉区)及双侧对称加固的混凝土柱,计算加固后的抗弯承载力是否满足要求。",
        "rule": "GB 50367-2013",
        "keywords": "受压构件正截面加固,增大截面加固法,增大截面加固钢筋混凝土偏心受压,加大截面法,单侧,受压区,受拉区,双侧对称加固混凝土柱,抗弯承载力,混凝土结构加固设计规范,GB 50367-2013,5.4.2",
        "create_time": "1/8/2015 00:00:00",
        "update_time": ""
    },
    {
        "id": "62",
        "parentId": "55",
        "appName": "外包型钢加固",
        "url": "153.asp",
        "icon": "外包型钢加固柱",
        "pic": "选",
        "state": "1",
        "description": "根据《加固》8.2.2节，采用外包角钢加固的混凝土柱,计算加固所需的角钢量，也可选用合适的等边或不等边角钢验算抗弯承载力是否满足要求。",
        "rule": "GB 50367-2013",
        "keywords": "外包型钢加固法,外粘型钢加固计算,外粘型钢加固钢筋混凝土偏心受压,外包角钢加固的混凝土柱,计算加固角钢量，等边或不等边角钢验算抗弯承载力,混凝土结构加固设计规范,GB 50367-2013,8.2.2",
        "create_time": "1/8/2015 00:00:00",
        "update_time": ""
    },
    {
        "id": "63",
        "parentId": "",
        "appName": "人防工程",
        "url": "",
        "icon": "",
        "pic": "",
        "state": "1",
        "description": "",
        "rule": "",
        "keywords": "",
        "create_time": "",
        "update_time": ""
    },
    {
        "id": "64",
        "parentId": "63",
        "appName": "人防无梁楼盖",
        "url": "159.asp",
        "icon": "人防无梁楼盖",
        "pic": "",
        "state": "1",
        "description": "根据《人民防空地下室设计规范》进行无梁楼盖柱帽抗冲切计算。",
        "rule": "GB 50038-2005",
        "keywords": "人防,无梁楼盖",
        "create_time": "18/9/2015 00:00:00",
        "update_time": ""
    },
    {
        "id": "65",
        "parentId": "63",
        "appName": "人防梁",
        "url": "168.asp",
        "icon": "受弯构件",
        "pic": "pic\\jx.gif",
        "state": "1",
        "description": "根据《人民防空地下室设计规范》进行梁抗弯、抗剪计算。",
        "rule": "GB 50038-2005",
        "keywords": "人防,梁计算",
        "create_time": "14/10/2015 00:00:00",
        "update_time": ""
    },
    {
        "id": "66",
        "parentId": "63",
        "appName": "人防单层外墙",
        "url": "150.asp",
        "icon": "人防单层外墙",
        "pic": "选",
        "state": "1",
        "description": "根据《人民防空地下室设计规范》和《混凝土规范》可选择计入竖向荷载的偏心受压构件模式或纯受弯构件模式进行地下单层外墙强度及延性比计算。",
        "rule": "GB 50038-2005",
        "keywords": "人防,单层外墙,延性比",
        "create_time": "3/9/2015 00:00:00",
        "update_time": ""
    },
    {
        "id": "67",
        "parentId": "63",
        "appName": "人防双层外墙",
        "url": "156.asp",
        "icon": "人防双层外墙",
        "pic": "选",
        "state": "1",
        "description": "根据《人民防空地下室设计规范》和《混凝土规范》可选择计入竖向荷载的偏心受压构件模式或纯受弯构件模式进行地下双层外墙强度及延性比计算。",
        "rule": "GB 50038-2005",
        "keywords": "人防,双层外墙,延性比",
        "create_time": "3/9/2015 00:00:00",
        "update_time": ""
    },
    {
        "id": "68",
        "parentId": "63",
        "appName": "人防楼梯",
        "url": "158.asp",
        "icon": "楼梯",
        "pic": "选",
        "state": "1",
        "description": "根据《人民防空地下室设计规范》考虑正反等效荷载进行楼梯梯板计算。",
        "rule": "GB 50038-2005",
        "keywords": "人防,楼梯,双面人防荷载",
        "create_time": "15/9/2015 00:00:00",
        "update_time": ""
    },
    {
        "id": "69",
        "parentId": "63",
        "appName": "人防给排水",
        "url": "155.asp",
        "icon": "人防给排水",
        "pic": "",
        "state": "1",
        "description": "根据《人民防空地下室设计规范》计算各防护单元内生活及饮用用水水箱的容积。",
        "rule": "GB 50038-2005",
        "keywords": "人防,给排水,人防水箱,人防排水计算,人防集水井",
        "create_time": "3/9/2015 00:00:00",
        "update_time": ""
    },
    {
        "id": "7",
        "parentId": "1",
        "appName": "地基修正",
        "url": "18.asp",
        "icon": "地基修正",
        "pic": "pic\\xz.gif",
        "state": "1",
        "description": "根据《地基》5.2.4编制，可根据各层土的容重精确计算地基承载力修正值。",
        "rule": "GB 50007-2011",
        "keywords": "大面积天然基础深度修正,地基承载力特征值,修正后的地基承载力特征值,浮重度,基础埋置深度,基础两侧的超载,建筑地基基础设计规范,GB50007-2011,5.2.4",
        "create_time": "1/8/2015 00:00:00",
        "update_time": ""
    },
    {
        "id": "70",
        "parentId": "63",
        "appName": "人防通风",
        "url": "157.asp",
        "icon": "人防通风",
        "pic": "",
        "state": "1",
        "description": "根据《人民防空地下室设计规范》计算各防护分区的清洁、滤毒通风量、隔绝防护时间、最小防毒通道换气次数及超压排气活门的数量。",
        "rule": "GB 50038-2005",
        "keywords": "人防,通风,风机,超压排气活门,悬板活门",
        "create_time": "9/9/2015 00:00:00",
        "update_time": ""
    },
    {
        "id": "71",
        "parentId": "63",
        "appName": "人防内墙",
        "url": "154.asp",
        "icon": "人防内部墙体",
        "pic": "选",
        "state": "1",
        "description": "根据《人民防空地下室设计规范》和《混凝土规范》可选择偏心受压构件及纯受弯构件两种计算模式进行计算,适用于计算临空墙、防护隔墙、密闭墙、扩散墙等。",
        "rule": "GB 50038-2005",
        "keywords": "临空墙,密闭墙,相邻防护单元隔墙计算",
        "create_time": "3/9/2015 00:00:00",
        "update_time": ""
    },
    {
        "id": "72",
        "parentId": "63",
        "appName": "人防底板反柱帽",
        "url": "160.asp",
        "icon": "反柱帽",
        "pic": "pic\\rffzm.gif",
        "state": "1",
        "description": "根据《人民防空地下室设计规范》无梁楼盖公式进行基础底板反柱帽抗冲切计算。",
        "rule": "GB 50038-2005",
        "keywords": "人防,无梁楼盖,人防板抗冲切,人防顶板",
        "create_time": "22/9/2015 00:00:00",
        "update_time": ""
    },
    {
        "id": "73",
        "parentId": "63",
        "appName": "人防双向板",
        "url": "171.asp",
        "icon": "双向板",
        "pic": "选",
        "state": "1",
        "description": "根据《人民防空地下室设计规范》和《混凝土规范》进行双向板内力及配筋计算.",
        "rule": "GB 50038-2005",
        "keywords": "人防顶板,人防双向板",
        "create_time": "16/10/2015 00:00:00",
        "update_time": ""
    },
    {
        "id": "74",
        "parentId": "63",
        "appName": "人防门框墙",
        "url": "507.asp",
        "icon": "人防门框墙",
        "pic": "选",
        "state": "1",
        "description": "根据《人民防空地下室设计规范》进行门框墙、门框柱、上档梁板的计算",
        "rule": "GB 50038-2005",
        "keywords": "人防,门框墙",
        "create_time": "27/6/2020 00:00:00",
        "update_time": ""
    },
    {
        "id": "75",
        "parentId": "63",
        "appName": "消波系统",
        "url": "230.asp",
        "icon": "消波系统",
        "pic": "",
        "state": "1",
        "description": "根据《人民防空地下室设计规范》附录F进行进风口、排风口的消波系统允许余压力值的计算",
        "rule": "GB 50038-2005",
        "keywords": "人防,消波,扩散室,悬板活门",
        "create_time": "3/2/2022 00:00:00",
        "update_time": ""
    },
    {
        "id": "76",
        "parentId": "",
        "appName": "砖与木结构",
        "url": "",
        "icon": "",
        "pic": "",
        "state": "1",
        "description": "",
        "rule": "",
        "keywords": "",
        "create_time": "",
        "update_time": ""
    },
    {
        "id": "77",
        "parentId": "76",
        "appName": "砌体偏心受压构件",
        "url": "8.asp",
        "icon": "受压构件",
        "pic": "选",
        "state": "1",
        "description": "无筋砌体构件受压构件计算是考虑烧结普通砖、烧结多孔砖、混凝土及轻骨料混凝土砌块、蒸压灰砂砖、蒸压粉煤灰砖、细料石、半细料石、粗料石、毛石在轴心受压或偏心受压情况下的受压承载力验算，可计算砌体结构砖柱、带壁柱或周边拉结墙等常见竖向砌体构件。",
        "rule": "GB 50003-2011",
        "keywords": "<li>本程序仅考虑设计中常用的矩形、T形受弯构件,e>0.\r\n<li>T形截面形心位置可以通过先计算然后在计算过程中查看 形心位置距翼缘顶为:？来得到后再输入偏心距计算。\r\n<li>受压构件H<sub>0</sub>根据《砌体结构设计规范》5.1.3条计算\r\n<li>对矩形截面构件，当轴向力偏心方向的截面边长大于另一方向的边长时，除按偏心受压构件计算外，还应对较小边长方向，按轴心受压进行验算。",
        "create_time": "22/5/2006 00:00:00",
        "update_time": ""
    },
    {
        "id": "78",
        "parentId": "76",
        "appName": "网状配筋砖偏心受压",
        "url": "9.asp",
        "icon": "受压构件",
        "pic": "pic\\JX1.gif",
        "state": "1",
        "description": "网状砖砌体构件属于配筋砖砌体构件计算范畴，计算将验算配置一定数量方格网钢筋的烧结普通砖、烧结多孔砖、混凝土及轻骨料混凝土砌块、蒸压灰砂砖、蒸压粉煤灰砖、细料石、半细料石、粗料石、毛石的受压构件承载力。",
        "rule": "GB 50003-2011",
        "keywords": "<li>受压构件H<sub>0</sub>根据《砌体结构设计规范》5.1.3条计算\r\n<li>对矩形截面构件，当轴向力偏心方向的截面边长大于另一方向的边长时，除按偏心受压构件计算外，还应对较小边长方向，按轴心受压进行验算。\r\n<li>当网状配筋砖砌体构件下端与无筋砌体交接时，尚应验算交接处无筋砌体的局部受压承载力.",
        "create_time": "22/5/2006 00:00:00",
        "update_time": ""
    },
    {
        "id": "79",
        "parentId": "76",
        "appName": "砌体双向偏心受压",
        "url": "10.asp",
        "icon": "受压构件",
        "pic": "pic\\JX2.gif",
        "state": "1",
        "description": "无筋砌体构件双向受压构件计算是考虑烧结普通砖、烧结多孔砖、混凝土及轻骨料混凝土砌块、蒸压灰砂砖、蒸压粉煤灰砖、细料石、半细料石、粗料石、毛石在双向偏心受压情况下的受压承载力验算，可计算砌体结构砖柱、带壁柱或周边拉结墙等常见竖向砌体构件。",
        "rule": "GB 50003-2011",
        "keywords": "<li>受压构件H<sub>0</sub>根据《砌体结构设计规范》5.1.3条计算",
        "create_time": "22/5/2006 00:00:00",
        "update_time": ""
    },
    {
        "id": "8",
        "parentId": "1",
        "appName": "软弱下卧层",
        "url": "24.asp",
        "icon": "软弱下卧层",
        "pic": "pic\\rrx.gif",
        "state": "1",
        "description": "根据《地基》5.2.7编制，可对多个软弱下卧层自动进行逐个计算，算出最大允许基础底面处的平均压力值即地基承载力 。",
        "rule": "GB 50007-2011",
        "keywords": "多个下卧层,自重压力值,软弱下卧层顶面处的附加压力",
        "create_time": "1/8/2015 00:00:00",
        "update_time": ""
    },
    {
        "id": "80",
        "parentId": "76",
        "appName": "受均匀压力砌体构件",
        "url": "11.asp",
        "icon": "受压构件",
        "pic": "选",
        "state": "1",
        "description": "砌体截面中受局部均匀压力时的承载力计算包括了4种不同位置的多孔砖砌体及灌孔混凝土砌块局部受压验算。",
        "rule": "GB 50003-2011",
        "keywords": "",
        "create_time": "22/5/2006 00:00:00",
        "update_time": ""
    },
    {
        "id": "81",
        "parentId": "76",
        "appName": "带壁柱墙有刚性垫块的砌体局部受压",
        "url": "12.asp",
        "icon": "局部受压",
        "pic": "pic\\dbzsy.gif",
        "state": "1",
        "description": "带壁柱墙在梁端设有刚性垫块的砌体局部受压是只为了防止梁端集中力对于砌体局部压力过大而造成砌体破坏而在梁端设置符合一定构造要求的刚性垫块，从而减小砌体局部受压的一种结构方法，适用于跨度较大的砌体结构非圈梁位置上搁置钢筋混凝土梁的局部受压验算。",
        "rule": "GB 50003-2011",
        "keywords": "<li>由于程序通过规范公式反求最小抗压强度设计值有困难，所以本程序采用验算法，输入您将使用的混凝土设计值，当不满足N<sub>0</sub>+N<sub>L</sub>≤ψ*γ<sub>1</sub>*f*A<sub>b</sub> (5.2.5-1)时请提高砌块强度或改变垫块尺寸。",
        "create_time": "22/5/2006 00:00:00",
        "update_time": ""
    },
    {
        "id": "82",
        "parentId": "76",
        "appName": "梁端砌体局部受压",
        "url": "13.asp",
        "icon": "局部受压",
        "pic": "选",
        "state": "1",
        "description": "梁端支承处砌体的局部受压是指在不采取任何措施的情况下，将梁直接搁置在砌体上，从而验算砌体是否会能够满足局部受压承载力要求。",
        "rule": "GB 50003-2011",
        "keywords": "",
        "create_time": "22/5/2006 00:00:00",
        "update_time": ""
    },
    {
        "id": "83",
        "parentId": "76",
        "appName": "有垫梁砌体局部受压",
        "url": "14.asp",
        "icon": "局部受压",
        "pic": "pic\\dlq.gif",
        "state": "1",
        "description": "砌体结构中搁置在砌体上的梁，下部设有垫梁，可以将荷载比较均匀的传递到砌体上，是一种用来承受较大梁端集中荷载的结构处理方法。",
        "rule": "GB 50003-2011",
        "keywords": "<li>梁下垫梁的长度必须大于πh<sub>0</sub>",
        "create_time": "22/5/2006 00:00:00",
        "update_time": ""
    },
    {
        "id": "84",
        "parentId": "76",
        "appName": "受拉砌体构件",
        "url": "15.asp",
        "icon": "受拉构件",
        "pic": "",
        "state": "1",
        "description": "轴心受拉砌体的承载力直接与砌体的轴心抗拉强度及砌体截面积有关。",
        "rule": "GB 50003-2011",
        "keywords": "",
        "create_time": "22/5/2006 00:00:00",
        "update_time": ""
    },
    {
        "id": "85",
        "parentId": "76",
        "appName": "受弯砌体构件",
        "url": "16.asp",
        "icon": "受弯构件",
        "pic": "pic\\sw.gif",
        "state": "1",
        "description": "砌体受弯构件的承载力与砌体截面抵抗矩及砌体弯曲抗拉强度设计值有关，程序可以计算矩形截面及T形截面的受弯应力。",
        "rule": "GB 50003-2011",
        "keywords": "",
        "create_time": "22/5/2006 00:00:00",
        "update_time": ""
    },
    {
        "id": "86",
        "parentId": "76",
        "appName": "墙梁",
        "url": "70.asp",
        "icon": "墙梁托梁",
        "pic": "选",
        "state": "1",
        "description": "墙梁包括简支墙梁、连续墙梁和框支墙梁。可划分为承重墙梁和自承重墙梁。程序会根据输入自动判断墙梁种类，并计算组成墙梁的混凝土托梁的配筋。",
        "rule": "GB 50003-2011",
        "keywords": "",
        "create_time": "22/5/2006 00:00:00",
        "update_time": ""
    },
    {
        "id": "87",
        "parentId": "76",
        "appName": "挑梁抗倾覆",
        "url": "97.asp",
        "icon": "挑梁",
        "pic": "选",
        "state": "1",
        "description": "砌体墙中钢筋混凝土挑梁应进行抗倾覆验算，根据挑梁上部压重的大小及形式以及挑梁所受的荷载计算挑梁的抗倾覆点及抗倾覆力矩是否满足倾覆要求。",
        "rule": "GB 50003-2011",
        "keywords": "",
        "create_time": "22/5/2006 00:00:00",
        "update_time": ""
    },
    {
        "id": "88",
        "parentId": "76",
        "appName": "拉弯压弯木构件",
        "url": "55.asp",
        "icon": "抗弯压弯木结构",
        "pic": "",
        "state": "1",
        "description": "适用于计算轴心受压、偏心受压、偏心受拉木构件，进行强度、稳定性及侧向稳定性计算。",
        "rule": "GB 50005-2017",
        "keywords": "",
        "create_time": "23/4/2016 00:00:00",
        "update_time": ""
    },
    {
        "id": "89",
        "parentId": "76",
        "appName": "木结构齿连接",
        "url": "56.asp",
        "icon": "木结构齿连接",
        "pic": "选",
        "state": "1",
        "description": "计算两块矩形木构件或原木构件单齿或双齿连接所能抵抗的轴力",
        "rule": "GB 50005-2017",
        "keywords": "",
        "create_time": "24/4/2016 00:00:00",
        "update_time": ""
    },
    {
        "id": "9",
        "parentId": "1",
        "appName": "反柱帽",
        "url": "161.asp",
        "icon": "反柱帽",
        "pic": "pic\\fzm.gif",
        "state": "1",
        "description": "根据《地基》8.4.7平板式筏基柱下冲切验算及8.4.10受剪切承载力编制，计算可以根据填入数据自动判断一次冲切及二次冲切，并且对是否满足45度冲切线要求 。",
        "rule": "GB 50007-2011",
        "keywords": "筏板冲切,受冲切承载力,截面高度影响系数,冲切破坏锥体,地基土单位净反力,扩展基础,建筑地基基础设计规范,GB50007-2011,8.4.7,8.4.10",
        "create_time": "1/8/2015 00:00:00",
        "update_time": ""
    },
    {
        "id": "90",
        "parentId": "76",
        "appName": "受弯木构件",
        "url": "53.asp",
        "icon": "受弯木结构off",
        "pic": "",
        "state": "1",
        "description": "适用于受弯木构件强度计算、侧向稳定计算及抗剪计算。",
        "rule": "GB 50005-2017",
        "keywords": "",
        "create_time": "25/4/2016 00:00:00",
        "update_time": ""
    },
    {
        "id": "91",
        "parentId": "",
        "appName": "幕墙及立面",
        "url": "",
        "icon": "",
        "pic": "",
        "state": "1",
        "description": "",
        "rule": "",
        "keywords": "",
        "create_time": "",
        "update_time": ""
    },
    {
        "id": "92",
        "parentId": "91",
        "appName": "框支承幕墙玻璃计算",
        "url": "180a.asp",
        "icon": "框支承幕墙玻璃计算",
        "pic": "",
        "state": "1",
        "description": "根据规范计算夹胶玻璃及夹胶中空玻璃在风荷载及地震作用下的最大应力和挠度。",
        "rule": "JGJ 102-2003",
        "keywords": "",
        "create_time": "11/7/2016 00:00:00",
        "update_time": ""
    },
    {
        "id": "93",
        "parentId": "91",
        "appName": "幕墙立柱计算",
        "url": "181a.asp",
        "icon": "幕墙立柱计算",
        "pic": "",
        "state": "1",
        "description": "根据规范计算玻璃或石材幕墙立柱在风荷载及地震作用下的最大应力和挠度。",
        "rule": "JGJ 102-2003",
        "keywords": "",
        "create_time": "10/8/2016 00:00:00",
        "update_time": ""
    },
    {
        "id": "94",
        "parentId": "91",
        "appName": "幕墙横梁计算",
        "url": "182a.asp",
        "icon": "幕墙横梁计算",
        "pic": "",
        "state": "1",
        "description": "本程序根据《玻璃幕墙工程技术规范》规范编制,用于计算玻璃幕墙、铝板幕墙及石材幕墙的横梁计算，可计算铝合金、型钢或铝合金加衬钢横梁类型。",
        "rule": "JGJ 102-2003",
        "keywords": "",
        "create_time": "29/9/2016 00:00:00",
        "update_time": ""
    },
    {
        "id": "95",
        "parentId": "91",
        "appName": "屋面排水计算",
        "url": "189.asp",
        "icon": "屋面排水设计off",
        "pic": "",
        "state": "1",
        "description": "计算屋面雨水量及天沟、雨水管排水能力",
        "rule": "GB 50015-2010",
        "keywords": "",
        "create_time": "25/8/2016 00:00:00",
        "update_time": ""
    },
    {
        "id": "96",
        "parentId": "",
        "appName": "道桥与施工",
        "url": "",
        "icon": "",
        "pic": "",
        "state": "1",
        "description": "",
        "rule": "",
        "keywords": "",
        "create_time": "",
        "update_time": ""
    },
    {
        "id": "97",
        "parentId": "96",
        "appName": "扣件式钢管外脚手架计算",
        "url": "192a.asp",
        "icon": "扣件式钢管外脚手架计算",
        "pic": "gif\\192.jpg",
        "state": "1",
        "description": "本程序根据《建筑施工扣件式钢管脚手架安全技术规范》(JGJ 130-2011) 编制,用于扣件式钢管脚手架设计计算.",
        "rule": "JGJ 130-2011",
        "keywords": "",
        "create_time": "13/10/2016 00:00:00",
        "update_time": ""
    },
    {
        "id": "98",
        "parentId": "96",
        "appName": "混凝土墙模板计算",
        "url": "193a.asp",
        "icon": "混凝土墙模板计算",
        "pic": "gif\\193.jpg",
        "state": "1",
        "description": "本程序根据《建筑施工模板安全技术规范》(JGJ 162-2008) 编制,用于现浇混凝土墙模板设计计算.",
        "rule": "JGJ 162-2008",
        "keywords": "",
        "create_time": "13/10/2016 00:00:00",
        "update_time": ""
    },
    {
        "id": "99",
        "parentId": "96",
        "appName": "桥梁横向分布系数",
        "url": "190.asp",
        "icon": "桥梁横向分布系数off",
        "pic": "gif\\qlfb.png",
        "state": "1",
        "description": "本程序根据《公路桥梁荷载横向分布计算》(李国豪) 编制",
        "rule": "JGT D60-2004",
        "keywords": "",
        "create_time": "27/7/2016 00:00:00",
        "update_time": ""
    }
];


const finiteAppList = [
    {
        "id": "1",
        "parentId": "",
        "appName": "有限元",
        "url": "",
        "icon": "",
        "pic": "",
        "state": "1",
        "description": "",
        "rule": "",
        "keywords": "",
        "create_time": "",
        "update_time": "",
        "child": [{
            "id": "2",
            "parentId": "1",
            "appName": "矩形水池计算",
            "url": "",
            "icon": "",
            "pic": "",
            "state": "1",
            "description": "",
            "rule": "",
            "keywords": "",
            "create_time": "",
            "update_time": ""
        }]
    },
]


export {
    rebarList,
    componentAppList,
    finiteAppList
}